import { Component } from "@angular/core"
import { FileTransfer } from "@ionic-native/file-transfer/ngx"
import { File } from "@ionic-native/file/ngx"
import { SplashScreen } from "@ionic-native/splash-screen/ngx"
import { StatusBar } from "@ionic-native/status-bar/ngx"
import {
  AlertController,
  LoadingController,
  NavController,
  Platform,
} from "@ionic/angular"
import { Storage } from "@ionic/storage"
import * as JSZip from "jszip"
import * as moment from "moment-timezone"
import { ngxCsv } from "ngx-csv/ngx-csv"
import * as papa from "papaparse"
import { HttpStorage, PublicService } from "./services/data-api/public.service"
import { NetState } from "./services/network/netState.service"
import {
  ConnectionStatus,
  NetworkService,
} from "./services/network/network.service"
import { OfflineManagerService } from "./services/offline/offline.service"
import { ScreensizeService } from "./services/screensize/screensize.service"

declare var window

var zip = new JSZip()
const API_STORAGE_KEY = "specialkey"
const keye = "entree"
const keys = "sortie"
const keyv = "vente"
const keyd = "depense"
const keyp = "produit"

declare var window
@Component({
  selector: "app-root",
  templateUrl: "app.component.html",
})
export class AppComponent {
  prenom = localStorage.getItem("nom")
  phone = localStorage.getItem("userId")
  token = localStorage.getItem("token")
  user_type = localStorage.getItem("user_type")
  is_Owner2 = () => localStorage.getItem("user_type") === "owner"
  is_Owner = false
  sync = false
  csvData: any[] = []
  headerRow: any = []
  csvData1: any[] = []
  val
  csvlist: any = []
  headerRow1: any = []
  csvData2: any[] = []
  headerRow2: any = []
  csvData3: any[] = []
  headerRow3: any = []
  csvData4: any[] = []
  headerRow4: any = []
  csvData5: any[] = []
  headerRow5: any = []
  csvData6: any[] = []
  headerRow6: any = []
  csvData7: any[] = []
  headerRow7: any = []
  csvData8: any[] = []
  headerRow8: any = []
  result: any
  shopname: any
  constructor(
    private platform: Platform,
    private splashScreen: SplashScreen,
    private statusBar: StatusBar,
    private navCtrl: NavController,
    private offlineManager: OfflineManagerService,
    private networkService: NetworkService,
    private publicService: PublicService,
    private screensizeService: ScreensizeService,
    private alertController: AlertController,
    private storage: Storage,
    private loadingController: LoadingController,
    private transfer: FileTransfer,
    private file: File,
    private netState: NetState,
    private loadingCtrl: LoadingController
  ) {
    window.app = this
    this.initializeApp()
    this.home()
    setTimeout(() => {
      this.prenom = localStorage.getItem("nom")
    }, 2000)
  }
  async getNom() {
    const loader = await this.loadingCtrl.create({
      message: "Chargement...",
      duration: 2000,
    })
    await loader.present()
    this.shopname = localStorage.getItem("shopname")
  }
  async setNom() {
    const loader = await this.loadingCtrl.create({
      message: "Chargement...",
      duration: 2000,
    })
    await loader.present()
    localStorage.setItem("shopname", "")
    localStorage.setItem("idBoutique", "")
  }

  async initializeApp() {
    this.platform.ready().then(() => {
      this.getNom()
      //   this.goToProduitss();
      this.statusBar.styleDefault()
      this.splashScreen.hide()
      this.networkService
        .onNetworkChange()
        .subscribe((status: ConnectionStatus) => {
          if (status === ConnectionStatus.Online) {
            this.offlineManager.checkForEvents().subscribe()
          }
        })
    })
  }

  menuChange() {
    this.prenom = localStorage.getItem("nom")
    this.token = localStorage.getItem("token")
    //window.location.reload();
  }

  private home() {
    if (localStorage.getItem("token")) {
      this.prenom = localStorage.getItem("nom")
      this.navCtrl.navigateRoot("/home")
    } else if (localStorage.getItem("tokenGerant")) {
      this.prenom = localStorage.getItem("nom")
      this.navCtrl.navigateRoot("/home-for-gerant")
    } else {
      this.prenom = localStorage.getItem("nom")
      this.navCtrl.navigateRoot("/beforelogin")
    }
  }

  // public acc = [{ title: 'Accueil', url: '/home', icon: 'ios-home' }];

  public logo = {
    logo: "../../../assets/images/profil.png",
  }

  async ngOnInit() {
    const loader = await this.loadingController.create({
      message: "Connexion...",
      duration: 2000,
    })
    await loader.present()
    this.getNom()
    if (this.user_type === "owner") {
      this.is_Owner = true
    }
    const ln = (i): boolean => i.length > 0
    await this.netState.addListener(true, async (ok) => {
      if (ok) {
        // HttpStorage.set("deleteds_clients", [])
        const localUsers = HttpStorage.get("added_users")
        const localBoutiques = HttpStorage.get("added_boutiques")
        const localUpdatedBoutiques = HttpStorage.get("updated_boutiques")
        const localDeletedBoutiques = HttpStorage.get("deleteds_boutiques")
        const localGerants = HttpStorage.get("added_gerants")
        const localStocks = HttpStorage.get("added_stocks")
        const localClients = HttpStorage.get("added_clients")
        const localVentes = HttpStorage.get("added_ventes")
        const localFour = HttpStorage.get("added_fournisseurs")
        const localUpdatedStock = HttpStorage.get("updated_stocks")
        const localDepenses = HttpStorage.get("added_depenses")
        const localCategories = HttpStorage.get("added_category")
        const localUpdatedVentes = HttpStorage.get("updated_ventes")
        const localUpdatedClients = HttpStorage.get("updated_clients")
        const localDeletedClients = HttpStorage.get("deleteds_clients")

        if (
          ln(localBoutiques) ||
          ln(localUpdatedBoutiques) ||
          ln(localDeletedBoutiques) ||
          ln(localFour) ||
          ln(localGerants) ||
          ln(localStocks) ||
          ln(localClients) ||
          ln(localVentes) ||
          ln(localUpdatedStock) ||
          ln(localDepenses) ||
          ln(localCategories) ||
          ln(localUpdatedVentes) ||
          ln(localUpdatedClients) ||
          ln(localDeletedClients) ||
          ln(localUsers)
        ) {
          this.sync = true
          const loader = await this.loadingCtrl.create({
            message: "Chargement...",
            duration: 2000,
          })
          await loader.present()

          try {
            for (let b of localUsers) {
              const { id, ...rest } = b
              const res = await this.publicService.addUser(rest, true)
              console.log("added user", res)
              for (let s of localBoutiques) {
                if (s.user == id) {
                  s.user = res.phone
                }
              }
            }

            for (let b of localUpdatedBoutiques) {
              const { id, ...rest } = b
              await this.publicService.updateBoutique(id, rest, true)
            }
            for (let b of localDeletedBoutiques) {
              await this.publicService.deleteBoutique2(b, true)
            }

            for (let b of localBoutiques) {
              const { id, ...rest } = b
              const res = await this.publicService.addboutiquefromoffline(
                rest,
                true
              )
              console.log("addedBoutique", res)
              for (let s of localStocks) {
                if (s.shop == id) {
                  s.shop = res.id
                }
              }
              for (let s of localGerants) {
                if (s.store == id) {
                  s.store = res.id
                }
              }
              for (let b of localClients) {
                if (b.shop == id) {
                  b.shop = res.id
                }
              }
              for (let b of localUpdatedClients) {
                if (b.shop == id) {
                  b.shop = res.id
                }
              }

              for (let b of localFour) {
                if (b.shop == id) {
                  b.shop = res.id
                }
              }
            }

            for (let b of localUpdatedStock) {
              const { id, ...rest } = b
              await this.publicService.updateProduit2(id, rest, true)
            }

            for (let s of localFour) {
              const res = await this.publicService.addFournisseur2(s, true)
              console.log("added Fournisseur", res)
            }

            for (let s of localGerants) {
              const res = await this.publicService.ajoutgerant2(s, true)
              console.log("added gerant", res)
            }

            for (let s of localDepenses) {
              const res = await this.publicService.addDepense2(s, true)
              console.log("added Depenses", res)
            }

            for (let s of localCategories) {
              const { id, ...rest } = s
              const res = await this.publicService.addCategory(rest, true)
              console.log("added category", res)
              for (let c of localStocks) {
                if (c.category == id) {
                  c.category = res.id
                  console.log("replace local stock id", id, "with", res.id)
                }
              }
            }

            for (let s of localStocks) {
              const { id, ...rest } = s
              const res = await this.publicService.addProduit2(rest, true)
              console.log("added product", res)
              for (let c of localVentes) {
                if (c.produit == id) {
                  c.produit = res.id
                  console.log("replace local stock id", id, "with", res.id)
                }
              }
            }
            for (let c of localClients) {
              const { id, ...rest } = c
              const res = await this.publicService.addClient2(rest, true)
              console.log("added customer", res)
              for (let c of localVentes) {
                if (c.customer == id) {
                  c.customer = res.id
                  console.log("replace local customer id", id, "with", res.id)
                }
              }
            }
            for (let c of localUpdatedClients) {
              const { id, ...rest } = c
              const res = await this.publicService.updateClient2(id, rest, true)
              console.log("updated client", res)
            }

            for (let b of localDeletedClients) {
              const { id, ...rest } = b
              const res = await this.publicService.deleteClient2(b, true)
              console.log("deleted customer", res)
              for (let c of localVentes) {
                if (c.customer == id) {
                  c.customer = null
                  console.log(
                    "replace local deleted customer id",
                    id,
                    "with",
                    res.id
                  )
                }
              }
            }

            for (let b of localVentes) {
              const { id, ...rest } = b
              const res = await this.publicService.addvente2(rest, true)
              console.log("addedVente", res)
            }
            for (let b of localUpdatedVentes) {
              const { id, ...rest } = b
              await this.publicService.updateVente2(id, rest, true)
            }
          } catch (ex) {
            console.log("error sync", ex)
          }

          HttpStorage.set("added_boutiques", [])
          HttpStorage.set("updated_boutiques", [])
          HttpStorage.set("deleteds_boutiques", [])
          HttpStorage.set("added_gerants", [])
          HttpStorage.set("added_stocks", [])
          HttpStorage.set("added_clients", [])
          HttpStorage.set("added_ventes", [])
          HttpStorage.set("added_fournisseurs", [])
          HttpStorage.set("updated_stocks", [])
          HttpStorage.set("added_depenses", [])
          HttpStorage.set("added_category", [])
          HttpStorage.set("updated_ventes", [])
          HttpStorage.set("updated_clients", [])
          HttpStorage.set("deleteds_clients", [])
          HttpStorage.set("added_users", [])

          this.sync = false
        }
      }
    })
    window.app = this
  }

  dashboard() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateRoot("/dashboard")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateRoot("/dashboard")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateRoot("/dashboard")
    }
  }

  accueil() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateRoot("/home")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateRoot("/home")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateRoot("/home-for-gerant")
    }
  }

  profil() {
    this.navCtrl.navigateForward("/profil")
  }

  journalier() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/jour")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/jour")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("journale")
    }
  }

  benefice() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("benefice")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("benefice")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("journale")
    }
  }

  accessInventaire() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("inventaires")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("inventaires")
    }
  }

  accessVentes() {
    if (this.storage.get("usertype")) {
      this.storage.get("boutique").then((val) => {
        this.val = val
        console.log(val)
      })
      this.navCtrl.navigateForward("/ventes")
    }
    if (localStorage.getItem("token")) {
      this.storage.get("boutique").then((val) => {
        this.val = val
        console.log(val)
      })
      this.navCtrl.navigateForward("/vente")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/vente-by-gerant")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/vente")
    }
  }

  accessDepense() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/depenses")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/depense")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/depense-by-gerant")
    }
  }

  accessPret() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/pret")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/pret")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/pret-by-gerant")
    }
  }

  accessBoutiques() {
    this.navCtrl.navigateForward("/mes-boutiques")
  }

  accessClient() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/clients")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("clients")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/client-by-gerant")
    }
  }

  accessFournisseur() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/fournisseurs")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/fournisseurs")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/fournisseur-by-gerant")
    }
  }

  accessCollaborateur() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/collaborateur")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/collaborateur")
    }
  }

  accessCollaborateursList() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/liste-collaborateurs")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/liste-collaborateurs")
    }
  }

  accessGerantsList() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/list-gerants")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/list-gerants")
    }
  }

  accessRetour() {
    //this.navCtrl.navigateForward('/fournisseurs');
    this.navCtrl.navigateForward("/retourproduits")
  }

  accessRetourList() {
    //this.navCtrl.navigateForward('/fournisseurs');
    this.navCtrl.navigateForward("/list-retours")
  }

  accessStock() {
    if (this.storage.get("usertype")) {
      this.navCtrl.navigateForward("/produits-by-shop")
    }
    if (localStorage.getItem("token")) {
      this.navCtrl.navigateForward("/produits")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/accueilSG/produits-by-gerant")
    } else if (localStorage.getItem("tokenGerant")) {
      this.navCtrl.navigateForward("/produit")
    }
  }

  /* hebdo() {
    this.navCtrl.navigateForward('hebdo');
  }

  mensuel() {
    this.navCtrl.navigateForward('month');
  } */

  contact() {
    this.navCtrl.navigateForward("menu/nouscontacter")
  }

  propos() {
    this.navCtrl.navigateForward("menu/apropos")
  }

  private handleError(err) {
    // console.log('something went wrong: ', err);
  }

  async onMenuo1Item() {
    const alert = await this.alertController.create({
      header: "Fewnu  version: 9.4.0",
      message:
        // tslint:disable-next-line: max-line-length
        "Fewnu est une application mobile de Gestion de boutique ou de business qui fonctionne sans connexion internet. Elle vous permet d'enregistrer vos ventes, vos dépenses et vos prêts quotidiens, de faire le résumé journalier, hebdomadaire et mensuel de vos ventes et dépenses ainsi que l'inventaire   L'application Fewnu vous permet de gérer aussi votre stock c'est à dire les entrées et les sorties de votre boutique.Avec Fewnu tenez votre boutique en main!!",
      buttons: [
        {
          text: "Ok",
        },
      ],
    })
    alert.present()
  }

  async onMenuoItem() {
    const alert = await this.alertController.create({
      header: "CONTACTEZ NOUS",
      message:
        " Tél: 774720737 / 782933656, <br> E-mail: contact.fewnu@gmail.com ",
      buttons: [
        {
          text: "Ok",
        },
      ],
    })
    alert.present()
  }

  async presentConfirm() {
    const alert = await this.alertController.create({
      header: "Déconnexion",
      message: "Voulez vous vraiment vous déconnecter?",
      buttons: [
        {
          text: "Non",
          role: "cancel",
          handler: () => {
            console.log("Cancel clicked")
          },
        },
        {
          text: "Oui",
          handler: () => {
            console.log("Buy clicked")
            this.logout()
          },
        },
      ],
    })
    alert.present()
  }

  async logout() {
    const loader = await this.loadingController.create({
      message: "Deconnexion...",
      duration: 2000,
    })
    await loader.present()
    localStorage.removeItem("shopname")
    localStorage.removeItem("idBoutique")
    localStorage.removeItem("token")
    localStorage.removeItem("tokenGerant")
    localStorage.removeItem("nom")
    this.prenom = ""
    this.storage.remove(`${API_STORAGE_KEY}-${keye}`)
    this.storage.remove(`${API_STORAGE_KEY}-${keys}`)
    this.storage.remove(`${API_STORAGE_KEY}-${keyv}`)
    this.storage.remove(`${API_STORAGE_KEY}-${keyd}`)
    this.storage.remove(`${API_STORAGE_KEY}-${keyp}`)
    loader.dismiss()
    this.navCtrl.navigateBack("/login")
  }

  /** CSV FILE FOR BOUTIQUE */
  async readCsvData() {
    if (
      this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline
    ) {
      this.storage.get("depenses").then((data) => {
        const result = data
        this.result = result
        this.csvData = this.result.map((item) => {
          return [
            moment(item.date).format("DD/MM/YYYY"),
            item.Designation,
            item.Prix,
          ]
        })
        this.headerRow = ["Date", "Designation", "Prix"]
      })
      this.storage.get("ventes").then((data) => {
        const result = data
        this.result = result
        this.csvData1 = this.result.map((item) => {
          // tslint:disable-next-line: max-line-length
          return [
            moment(item.Date).format("DD/MM/YYYY"),
            item.Designation,
            item.quantite,
            item.prix_unit_achat + " FCFA",
            item.prix_unit_vente + " FCFA",
            item.customer ? item.customer.nom_complet : item.customer,
          ]
        })
        this.headerRow1 = [
          "Date",
          "Designation",
          "Quantite",
          "Prix Unitaire d'achat",
          "Prix unitaire de vente",
          "Client",
        ]
      })
      this.storage.get("prets").then((data) => {
        const result = data
        this.result = result
        this.csvData2 = this.result.map((item) => {
          return [
            moment(item.date).format("DD/MM/YYYY"),
            item.Client ? item.Client : item.customer.nom_complet,
            item.Telephone,
            item.Designation,
            item.Montant,
          ]
        })
        this.headerRow2 = ["Date", "Client", "Telephone", "Libelle", "Montant"]
      })
    } else {
      /** Depenses */
      this.publicService
        .loadDepense()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web connection', ConnectionStatus.Offline);
              this.csvData = this.result.map((item) => {
                // // console.log(moment(item.Date).format('DD/MM/YYYY'));
                return [
                  moment(item.Date).format("DD/MM/YYYY"),
                  item.Designation,
                  item.Prix,
                ]
              })
              this.headerRow = ["Date", "Designation", "Prix"]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("depenses").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.Designation,
                  item.Prix,
                ]
              })
              this.headerRow = ["Date", "Designation", "Prix"]
              // console.log('depenses', this.csvData);
            })
          }
        )

      /** Ventes */
      this.publicService
        .loadVente()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web', this.result);
              this.csvData1 = this.result.map((item) => {
                // tslint:disable-next-line: max-line-length
                return [
                  moment(item.Date).format("DD/MM/YYYY"),
                  item.Designation,
                  item.quantite,
                  item.prix_unit_achat + " FCFA",
                  item.prix_unit_vente + " FCFA",
                  item.customer ? item.customer.nom_complet : item.customer,
                ]
              })
              this.headerRow1 = [
                "Date",
                "Designation",
                "Quantite",
                "Prix Unitaire d'achat",
                "Prix unitaire de vente",
                "Client",
              ]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("ventes").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData1 = this.result.map((item) => {
                // tslint:disable-next-line: max-line-length
                return [
                  moment(item.Date).format("DD/MM/YYYY"),
                  item.Designation,
                  item.quantite,
                  item.prix_unit_achat + " FCFA",
                  item.prix_unit_vente + " FCFA",
                  item.customer ? item.customer.nom_complet : item.customer,
                ]
              })
              this.headerRow1 = [
                "Date",
                "Designation",
                "Quantite",
                "Prix Unitaire d'achat",
                "Prix unitaire de vente",
                "Client",
              ]
              // console.log('depenses', this.csvData);
            })
          }
        )

      /** Prets */
      this.publicService
        .loadPret()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web', this.result);
              this.csvData2 = this.result.map((item) => {
                return [
                  item.Date,
                  item.Client ? item.Client : item.customer.nom_complet,
                  item.Telephone,
                  item.Designation,
                  item.Montant,
                ]
              })
              this.headerRow2 = [
                "Date",
                "Client",
                "Telephone",
                "Libelle",
                "Montant",
              ]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("prets").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData2 = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.Client ? item.Client : item.customer.nom_complet,
                  item.Telephone,
                  item.Designation,
                  item.Montant,
                ]
              })
              this.headerRow2 = [
                "Date",
                "Client",
                "Telephone",
                "Libelle",
                "Montant",
              ]
              // console.log('depenses', this.csvData);
            })
          }
        )
    }
    /* this.https.get('assets/dummyData.csv')
      .subscribe(
      data => this.extractData(data),
      err => this.handleError(err)
      ); */
  }

  /** CSV FILE FOR STOCK */
  async readCsvDataStock() {
    if (
      this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline
    ) {
      this.storage.get("produits").then((data) => {
        const result = data
        // console.log('result', result);
        this.result = result
        this.csvData3 = this.result.map((item) => {
          return [
            moment(item.date).format("DD/MM/YYYY"),
            item.produit,
            item.quantite,
          ]
        })
        this.headerRow3 = ["Date", "Produit", "Quantite"]
      })
      this.storage.get("entrees").then((data) => {
        const result = data
        // console.log('result', result);
        this.result = result
        this.csvData4 = this.result.map((item) => {
          // tslint:disable-next-line: max-line-length
          return [
            moment(item.Date).format("DD/MM/YYYY"),
            item.Produit ? item.Produit : item.product.produit,
            item.Quantite,
            item.Prix_Unitaire + " FCFA",
            item.Quantite * item.Prix_Unitaire + " FCFA",
            item.fournisseur ? item.fournisseur.nom_complet : item.fournisseur,
          ]
        })
        this.headerRow4 = [
          "Date",
          "Produit",
          "Quantite",
          "Prix Unitaire",
          "Prix Total",
          "Fournisseur",
        ]
      })
      this.storage.get("sorties").then((data) => {
        const result = data
        // console.log('result', result);
        this.result = result
        this.csvData5 = this.result.map((item) => {
          return [
            moment(item.Date).format("DD/MM/YYYY"),
            item.Produit ? item.Produit : item.product.produit,
            item.Quantite,
            item.Prix_Unitaire + " FCFA",
            item.Quantite * item.Prix_Unitaire + " FCFA",
            item.customer ? item.customer.nom_complet : item.Client,
          ]
        })
        this.headerRow5 = [
          "Date",
          "Produit",
          "Quantite",
          "Prix Unitaire",
          "Prix Total",
          "Client",
        ]
        // // console.log('depenses', this.csvData);
      })
    } else {
      this.publicService
        .loadProduits()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web', this.result);
              this.csvData3 = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.produit,
                  item.quantite,
                ]
              })
              this.headerRow3 = ["Date", "Produit", "Quantite"]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("produits").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData3 = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.produit,
                  item.quantite,
                ]
              })
              this.headerRow3 = ["Date", "Produit", "Quantite"]
            })
          }
        )

      /** Entrees */
      this.publicService
        .loadEntree()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web', this.result);
              this.csvData4 = this.result.map((item) => {
                // tslint:disable-next-line: max-line-length
                return [
                  moment(item.Date).format("DD/MM/YYYY"),
                  item.Produit ? item.Produit : item.product.produit,
                  item.Quantite,
                  item.Prix_Unitaire + " FCFA",
                  item.Quantite * item.Prix_Unitaire + " FCFA",
                  item.fournisseur
                    ? item.fournisseur.nom_complet
                    : item.fournisseur,
                ]
              })
              this.headerRow4 = [
                "Date",
                "Produit",
                "Quantite",
                "Prix Unitaire",
                "Prix Total",
                "Fournisseur",
              ]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("entrees").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData4 = this.result.map((item) => {
                // tslint:disable-next-line: max-line-length
                return [
                  moment(item.Date).format("DD/MM/YYYY"),
                  item.Produit ? item.Produit : item.product.produit,
                  item.Quantite,
                  item.Prix_Unitaire + " FCFA",
                  item.Quantite * item.Prix_Unitaire + " FCFA",
                  item.fournisseur
                    ? item.fournisseur.nom_complet
                    : item.fournisseur,
                ]
              })
              this.headerRow4 = [
                "Date",
                "Produit",
                "Quantite",
                "Prix Unitaire",
                "Prix Total",
                "Fournisseur",
              ]
            })
          }
        )

      /** Sorties */
      this.publicService
        .loadSortie()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web', this.result);
              this.csvData5 = this.result.map((item) => {
                return [
                  moment(item.Date).format("DD/MM/YYYY"),
                  item.Produit ? item.Produit : item.product.produit,
                  item.Quantite,
                  item.Prix_Unitaire + " FCFA",
                  item.Quantite * item.Prix_Unitaire + " FCFA",
                  item.customer ? item.customer.nom_complet : item.Client,
                ]
              })
              this.headerRow5 = [
                "Date",
                "Produit",
                "Quantite",
                "Prix Unitaire",
                "Prix Total",
                "Client",
              ]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("sorties").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData5 = this.result.map((item) => {
                return [
                  moment(item.Date).format("DD/MM/YYYY"),
                  item.Produit ? item.Produit : item.product.produit,
                  item.Quantite,
                  item.Prix_Unitaire + " FCFA",
                  item.Quantite * item.Prix_Unitaire + " FCFA",
                  item.customer ? item.customer.nom_complet : item.Client,
                ]
              })
              this.headerRow5 = [
                "Date",
                "Produit",
                "Quantite",
                "Prix Unitaire",
                "Prix Total",
                "Client",
              ]
              // // console.log('depenses', this.csvData);
            })
          }
        )
    }
    /* this.https.get('assets/dummyData.csv')
      .subscribe(
      data => this.extractData(data),
      err => this.handleError(err)
      ); */
  }

  /** CSV FILE FOR STOCK */
  async readCsvDatas() {
    if (
      this.networkService.getCurrentNetworkStatus() === ConnectionStatus.Offline
    ) {
      this.storage.get("clients").then((data) => {
        const result = data
        // console.log('result', result);
        this.result = result
        this.csvData6 = this.result.map((item) => {
          return [
            moment(item.date).format("DD/MM/YYYY"),
            item.nom_complet,
            item.telephone,
            item.adresse,
            item.email,
          ]
        })
        this.headerRow6 = ["Date", "Nom", "Telephone", "Adresse", "Email"]
      })
      this.storage.get("fournisseurs").then((data) => {
        const result = data
        // console.log('result', result);
        this.result = result
        this.csvData7 = this.result.map((item) => {
          return [
            moment(item.date).format("DD/MM/YYYY"),
            item.nom_complet,
            item.telephone,
            item.adresse,
            item.email,
          ]
        })
        this.headerRow7 = ["Date", "Nom", "Telephone", "Adresse", "Email"]
      })
      this.storage.get("boutiques").then((data) => {
        const result = data
        // console.log('result', result);
        this.result = result
        this.csvData8 = this.result.map((item) => {
          return [
            item.nom_gerant,
            item.nom_boutique,
            item.phone,
            item.adresse,
            item.email,
          ]
        })
        this.headerRow8 = [
          "Nom Gerant",
          "Nom Boutique",
          "Telephone",
          "Adresse",
          "Email",
        ]
      })
    } else {
      this.publicService
        .loadClients()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web', this.result);
              this.csvData6 = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.nom_complet,
                  item.telephone,
                  item.adresse,
                  item.email,
                ]
              })
              this.headerRow6 = ["Date", "Nom", "Telephone", "Adresse", "Email"]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("clients").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData6 = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.nom_complet,
                  item.telephone,
                  item.adresse,
                  item.email,
                ]
              })
              this.headerRow6 = ["Date", "Nom", "Telephone", "Adresse", "Email"]
            })
          }
        )

      /** Fournisseurs */
      this.publicService
        .loadFournisseur()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result.data
              // console.log('web', this.result);
              this.csvData7 = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.nom_complet,
                  item.telephone,
                  item.adresse,
                  item.email,
                ]
              })
              this.headerRow7 = ["Date", "Nom", "Telephone", "Adresse", "Email"]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("fournisseurs").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData7 = this.result.map((item) => {
                return [
                  moment(item.date).format("DD/MM/YYYY"),
                  item.nom_complet,
                  item.telephone,
                  item.adresse,
                  item.email,
                ]
              })
              this.headerRow7 = ["Date", "Nom", "Telephone", "Adresse", "Email"]
            })
          }
        )

      /** Boutiques */
      this.publicService
        .loadBoutiques()
        //@ts-ignore
        .pipe()
        .subscribe(
          (data) => {
            if (data) {
              const result: any = data
              this.result = result
              // console.log('web', this.result);
              this.csvData8 = this.result.map((item) => {
                return [
                  item.nom_gerant,
                  item.nom_boutique,
                  item.phone,
                  item.adresse,
                  item.email,
                ]
              })
              this.headerRow8 = [
                "Nom Gerant",
                "Nom Boutique",
                "Telephone",
                "Adresse",
                "Email",
              ]
            }
          },
          () => {
            // console.log('err', err);
            this.storage.get("boutiques").then((data) => {
              const result = data
              // console.log('result', result);
              this.result = result
              this.csvData8 = this.result.map((item) => {
                return [
                  item.nom_gerant,
                  item.nom_boutique,
                  item.phone,
                  item.adresse,
                  item.email,
                ]
              })
              this.headerRow8 = [
                "Nom Gerant",
                "Nom Boutique",
                "Telephone",
                "Adresse",
                "Email",
              ]
            })
          }
        )
    }
    /* this.https.get('assets/dummyData.csv')
      .subscribe(
      data => this.extractData(data),
      err => this.handleError(err)
      ); */
  }

  async downloadCSV() {
    /*  await this.downloadDepenseCSV();
    await this.downloadVenteCSV();
    await this.downloadPretCSV();
    await this.downloadProduitCSV();
    await this.downloadEntreeCSV();
    await this.downloadSortiesCSV();
    await this.downloadClientsCSV();
    await this.downloadFournisseursCSV(); 
     zip.generateAsync({ type: "blob" }).then(function (content) {
      // see FileSaver.js
      // console.log('content', content);
      saveAs(content, "fewnu_" + moment().format("DD/MM/YYYY") + ".zip");
    });
*/
    await this.VenteCsv()
    await this.produitCsv()
    await this.fournisseurCsv()
  }

  async fournisseurCsv() {
    this.publicService
      .loadFournisseur()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        console.log(data)
        if (data) {
          this.csvlist = data
        }
      })

    var options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Product File CSV",
      useBom: true,
      noDownload: false,
    }

    new ngxCsv(this.csvlist.data, "fournisseur", options)
  }

  async produitCsv() {
    const data = await this.publicService.getProduitsByBoutique2(
      this.netState.inConnected()
    )

    for (let i of data) {
      let t = {
        produit: i.produit,
        prix: i.prix,
        quantite: i.quantite,
        shop: i.shop,
      }
      this.csvlist.push(t)
    }

    var options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Product File CSV",
      useBom: true,
      noDownload: false,
      headers: ["Produit", "Prix", "Quantite", "ShopID"],
    }

    new ngxCsv(this.csvlist, "produit", options)
  }

  async VenteCsv() {
    const data = await this.publicService.getVenteByBoutique2(
      this.netState.inConnected()
    )
    this.csvlist = []
    for (let i of data) {
      let t = {
        id: i.id,
        client: i.customer ? i.customer.nom_complet : "",
        Vendeur: i.id_userv.nom_complet,
        produit: i.produit.produit,
        quantite: i.quantite,
        prix: i.prix_unit_vente,
        shop: i.shop.id,
      }
      this.csvlist.push(t)
    }

    var options = {
      fieldSeparator: ",",
      quoteStrings: '"',
      decimalseparator: ".",
      showLabels: true,
      showTitle: true,
      title: "Product File CSV",
      useBom: true,
      noDownload: false,
      headers: [
        "ID",
        "Cient",
        "Vendeur",
        "Produit",
        "Quantite",
        "Prix Unitaire",
        "ShopID",
      ],
    }

    new ngxCsv(this.csvlist, "Vente", options)
  }

  async downloadDepenseCSV() {
    const csv = papa.unparse({
      fields: this.headerRow,
      data: this.csvData,
    })
    // Dummy implementation for Desktop download purpose
    const blob = new Blob([csv])
    zip.file("depenses.csv", blob)
    // Dummy implementation for Desktop download purpose
    /* const blob = new Blob([csv]);
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'newdata.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); */
  }

  async downloadVenteCSV() {
    const csv = papa.unparse({
      fields: this.headerRow1,
      data: this.csvData1,
    })
    // // console.log('csv file', csv);

    // Dummy implementation for Desktop download purpose
    const blob = new Blob([csv])
    zip.file("ventes.csv", blob)

    // Dummy implementation for Desktop download purpose
    /* const blob = new Blob([csv]);
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'newdata.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); */
  }

  async downloadPretCSV() {
    const csv = papa.unparse({
      fields: this.headerRow2,
      data: this.csvData2,
    })
    // // console.log('csv file', csv);

    // Dummy implementation for Desktop download purpose
    const blob = new Blob([csv])
    zip.file("prets.csv", blob)

    // Dummy implementation for Desktop download purpose
    /* const blob = new Blob([csv]);
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'newdata.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); */
  }

  async downloadProduitCSV() {
    const csv = papa.unparse({
      fields: this.headerRow3,
      data: this.csvData3,
    })
    // // console.log('csv file', csv);

    // Dummy implementation for Desktop download purpose
    const blob = new Blob([csv])
    zip.file("produits.csv", blob)

    // Dummy implementation for Desktop download purpose
    /* const blob = new Blob([csv]);
    const a = window.document.createElement('a');
    a.href = window.URL.createObjectURL(blob);
    a.download = 'newdata.csv';
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a); */
  }

  async downloadEntreeCSV() {
    const csv = papa.unparse({
      fields: this.headerRow4,
      data: this.csvData4,
    })
    // // console.log('csv file', csv);

    // Dummy implementation for Desktop download purpose
    const blob = new Blob([csv])
    zip.file("entrees.csv", blob)
  }

  async downloadSortiesCSV() {
    const csv = papa.unparse({
      fields: this.headerRow5,
      data: this.csvData5,
    })

    const blob = new Blob([csv])
    zip.file("sorties.csv", blob)
  }

  async downloadClientsCSV() {
    const csv = papa.unparse({
      fields: this.headerRow6,
      data: this.csvData6,
    })

    const blob = new Blob([csv])
    zip.file("clients.csv", blob)
  }

  async downloadFournisseursCSV() {
    const csv = papa.unparse({
      fields: this.headerRow7,
      data: this.csvData7,
    })

    const blob = new Blob([csv])
    zip.file("fournisseurs.csv", blob)
  }
}
