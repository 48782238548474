import { Injectable } from "@angular/core"
import { Storage } from "@ionic/storage"
import { Observable, from, of, forkJoin } from "rxjs"
import { switchMap, finalize } from "rxjs/operators"
import { ToastController } from "@ionic/angular"
import { HTTP } from "@ionic-native/http/ngx"

const STORAGE_REQ_KEY = "storedreq"

interface StoredRequest {
  url: string
  type: any
  data: any
  date: number
  id: string
}

@Injectable({
  providedIn: "root",
})
export class OfflineManagerService {
  API_STORAGE_KEY = "specialkey"
  constructor(
    private storage: Storage,
    private http: HTTP,
    private toastController: ToastController
  ) {}

  checkForEvents(): Observable<any> {
    return from(this.storage.get(STORAGE_REQ_KEY)).pipe(
      switchMap((storedOperations) => {
        const storedObj = JSON.parse(storedOperations)
        if (storedObj && storedObj.length > 0) {
          return this.sendRequests(storedObj).pipe(
            finalize(() => {
              const toast = this.toastController.create({
                message: `Synchronisation des données locales dans la base de données !`,
                duration: 2000,
                position: "bottom",
              })
              toast.then((toast) => toast.present())

              this.storage.remove(STORAGE_REQ_KEY)
            })
          )
        } else {
          console.log("no local events to sync")
          return of(false)
        }
      })
    )
  }

  storeRequest(url, type, data) {
    const toast = this.toastController.create({
      message: `Vos données sont stockées localement car vous semblez être hors ligne. Elles seront disponible une fois en ligne.`,
      duration: 2000,
      position: "bottom",
    })
    toast.then((toast) => toast.present())
    const id = localStorage.getItem("userId")
    const action: StoredRequest = {
      url,
      type,
      data,
      date: new Date().getTime(),
      id: Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, "")
        .substr(0, 5),
    }

    return this.storage.get(STORAGE_REQ_KEY).then((storedOperations) => {
      let storedObj = JSON.parse(storedOperations)

      if (storedObj) {
        storedObj.push(action)
      } else {
        storedObj = [action]
      }
      console.log(storedObj)
      //  Sauvegardez les anciennes et nouvelles transactions locales dans le storage
      return this.storage.set(STORAGE_REQ_KEY, JSON.stringify(storedObj))
    })
  }

  sendRequests(operations: StoredRequest[]) {
    const obs = []
    console.log("operation", operations)
    for (const op of operations) {
      console.log("Make one request: ", op)
      const oneObs = this.http.sendRequest(op.url, {
        method: op.type,
        data: op.data,
      })
      obs.push(oneObs)
    }
    console.log(obs)
    // Envoyez tous les événements locaux et revenez une fois qu'ils sont terminés
    return forkJoin(obs)
  }
}
