import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { HTTP } from "@ionic-native/http/ngx";
import { rootUrl } from "../../url-api/server-url";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private httpp: HTTP, private http: HttpClient) {}

  createUser(user) {
    return this.httpp.post(rootUrl + "/api/registeruser/", user, {});
  }

  register(user) {
    return this.http.post(rootUrl + "/api/registeruser/", user, {});
  }

  login(user) {
    console.log(user);
    return this.httpp.post(rootUrl + "/api/loginuser/", user, {});
  }

  resetPassword(email) {
    console.log("email", email);
    return this.httpp.post(rootUrl + "/api/password_reset/", email, {});
  }

  resetPasswordConfirm(data) {
    console.log("data", data);
    return this.httpp.post(rootUrl + "/api/password_reset/confirm/", data, {});
  }

  passwordReset(email) {
    return this.http.post(rootUrl + "/api/password_reset/", email, {});
  }

  passwordResetConfirm(data) {
    console.log("data", data);
    return this.http.post(rootUrl + "/api/password_reset/confirm/", data, {});
  }

  signin(user) {
    return this.http.post(rootUrl + "/api/loginuser/", user, {});
  }

  logout() {
    localStorage.removeItem("userId");
  }
}
