<ion-app>
  <ion-split-pane contentId="main">
    <ion-menu side="start" menuId="test" contentId="main">
      <ion-header>
        <ion-toolbar class="ion" color="primary">
          <div class="bordure" button (click)="profil()">
            <ion-icon name="person" class="icon_user"></ion-icon>
            <br />
            <ion-label
              ><b>{{ prenom }}</b></ion-label
            >
            <br />
            <ion-label *ngIf="is_Owner2()">Propriétaire</ion-label>
            <ion-label *ngIf="!is_Owner2()">Gérant</ion-label>
          </div>
          <div class="titre" button>
            <ion-row>
              <ion-col size="6">
                <ion-button class="pf-btn" (click)="profil()">
                  <ion-icon
                    slot="icon-only"
                    name="person"
                  ></ion-icon> </ion-button
                ><br />
                <ion-label>Voir mon profil</ion-label>
              </ion-col>
              <ion-col size="6">
                <ion-button class="pf-btn" (click)="presentConfirm()">
                  <ion-icon
                    slot="icon-only"
                    name="log-out"
                  ></ion-icon> </ion-button
                ><br />
                <ion-label>Se déconnecter</ion-label>
              </ion-col>
            </ion-row>
          </div>
        </ion-toolbar>
      </ion-header>
      <ion-content>
        <ion-list>
          <ion-menu-toggle auto-hide="false">
            <ion-item
              button
              (click)="dashboard()"
              routerDirection="root"
              [hidden]="!is_Owner2() === true"
              routerLink="/dashboard"
              routerLinkActive="active-item"
              [routerLinkActive]="{ exact: true }"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="stats-chart-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Tableau de bord</ion-label>
              </div>
            </ion-item>
            <ion-item
              button
              (click)="accueil()"
              routerDirection="root"
              routerLink="/home"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="home-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Menu Général</ion-label>
              </div>
            </ion-item>
            <!-- <ion-item-divider class="ion" color="primary"
              >BOUTIQUE</ion-item-divider
            > -->
            <ion-item
              *ngIf="token"
              button
              (click)="accessBoutiques()"
              routerLink="/mes-boutiques"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="business-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Mes boutiques</ion-label>
              </div>
            </ion-item>
            <ion-item
              button
              (click)="accessFournisseur()"
              [hidden]="!is_Owner2() === true"
              routerLink="/fournisseurs"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="person-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Fournisseurs</ion-label>
              </div>
            </ion-item>

            <ion-item
              button
              (click)="accessVentes()"
              routerLink="/vente"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="card-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Ventes</ion-label>
              </div>
            </ion-item>
            <ion-item
              button
              (click)="accessStock()"
              routerLink="/produits"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="cube-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Stock</ion-label>
              </div>
            </ion-item>
            <ion-item
              button
              (click)="accessDepense()"
              routerLink="/depense"
              routerLinkActive="active-item"
            >
              <ion-icon class="menu-icon" slot="start" name="cash"></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Dépenses</ion-label>
              </div>
            </ion-item>
            <ion-item
              button
              (click)="accessPret()"
              routerLink="/pret"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="logo-usd"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Prêts</ion-label>
              </div>
            </ion-item>
            <ion-item
              button
              (click)="accessClient()"
              routerLink="/clients"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="people-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Clients</ion-label>
              </div>
            </ion-item>
            <!--
            <div>
              <ion-item
                button
                (click)="accessCollaborateursList()"
                
                [hidden]="!is_Owner2() === true"
              >
                <ion-icon
                  class="menu-icon"
                  slot="start"
                  name="ios-contacts"
                ></ion-icon>
                <div class="my-container-label">
                  <ion-label class="my-label-title">Collaborateurs</ion-label>
                </div>
              </ion-item>
            </div>
           -->

            <div>
              <ion-item
                button
                (click)="accessInventaire()"
                [hidden]="!is_Owner2() === true"
                routerLink="/inventaires"
                routerLinkActive="active-item"
              >
                <ion-icon
                  class="menu-icon"
                  slot="start"
                  name="book-outline"
                ></ion-icon>
                <div class="my-container-label">
                  <ion-label class="my-label-title">Inventaires</ion-label>
                </div>
              </ion-item>
            </div>
            <ion-item
              button
              (click)="journalier()"
              [hidden]="!is_Owner2() === true"
              routerLink="/jour"
              routerLinkActive="active-item"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="calendar-number-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">Bilans</ion-label>
              </div>
            </ion-item>

            <ion-item
              button
              *ngIf="token"
              (click)="downloadCSV()"
              [hidden]="!is_Owner2() === true"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="download-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title"
                  >Sauvegardez vos données</ion-label
                >
              </div>
            </ion-item>
            <ion-item
              button
              (click)="onMenuo1Item()"
              [hidden]="!is_Owner2() === true"
            >
              <ion-icon
                class="menu-icon"
                slot="start"
                name="help-circle-outline"
              ></ion-icon>
              <div class="my-container-label">
                <ion-label class="my-label-title">A Propos</ion-label>
              </div>
            </ion-item>
          </ion-menu-toggle>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main"> </ion-router-outlet>
  </ion-split-pane>
</ion-app>
