import { ErrorHandler, Injectable } from "@angular/core";

@Injectable()
export class SentryIonicErrorHandler extends ErrorHandler {
  handleError(error) {
    super.handleError(error);
    try {
      console.log("error", error);
    } catch (e) {
      console.error(e);
    }
  }
}
