import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { Storage } from "@ionic/storage";
import { PublicService } from "../data-api/public.service";

const API_STORAGE_KEY = "specialkey";
@Injectable({
  providedIn: "root",
})
export class StorageService {
  keyv = "vente";

  keyd = "depense";

  keyp = "pret";

  keyentree = "entree";

  keysortie = "sortie";

  keyhistoriEnt = "histEntr";

  keyHistSorti = "histSort";

  result: any;
  sale;

  constructor(
    private publc: PublicService,
    private storage: Storage,
    private plt: Platform
  ) {}

  // Save result of API requests
  private setLocalData(key, data) {
    this.storage.set(`${API_STORAGE_KEY}-${key}`, data);
  }

  // Get cached API result
  private getLocalData(key) {
    return this.storage.get(`${API_STORAGE_KEY}-${key}`).then((val) => {
      if (val !== null && val !== undefined) {
        this.result = val;
        console.log("val", this.result);

        // console.log(this.result.data[0].Date)
      }
    });
  }
  getDataVente() {
    this.publc
      .loadVente()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.sale = this.result.data;
        this.storage.set("ventes", this.result.data);
      });
  }

  getDataDepense() {
    this.publc
      .loadDepense()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.result = this.result.data;
        this.storage.set("depenses", this.result);
      });
  }

  getDataPret() {
    this.publc
      .loadPret()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.result = this.result.data;
        this.storage.set("prets", this.result);
      });
  }

  getDataEntree() {
    this.publc
      .loadEntree()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.storage.set("entrees", this.result.data);
      });
  }

  getDataSortie() {
    this.publc
      .loadSortie()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.storage.set("sorties", this.result.data);
      });
  }

  getDataProduit() {
    this.publc
      .loadProduits()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.storage.set("produits", this.result.data);
      });
  }

  getDataClient() {
    this.publc
      .loadClients()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.storage.set("clients", this.result.data);
      });
  }

  getDataFournisseur() {
    this.publc
      .loadFournisseur()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.storage.set("fournisseurs", this.result.data);
      });
  }

  getDataBoutique() {
    this.publc
      .loadBoutiques()
      //@ts-ignore
      .pipe()
      .subscribe((data) => {
        this.result = data;
        this.storage.set("boutiques", this.result);
      });
  }
}
