import { HttpClientModule } from "@angular/common/http"
import { ErrorHandler, NgModule } from "@angular/core"
import { FormBuilder } from "@angular/forms"
import { BrowserModule } from "@angular/platform-browser"
import { FileOpener } from "@ionic-native/file-opener/ngx"
import {
  FileTransfer,
  FileTransferObject,
} from "@ionic-native/file-transfer/ngx"
import { File } from "@ionic-native/file/ngx"
import { HTTP } from "@ionic-native/http/ngx"
import { Network } from "@ionic-native/network/ngx"
import { SocialSharing } from "@ionic-native/social-sharing/ngx"
import { SplashScreen } from "@ionic-native/splash-screen/ngx"
import { StatusBar } from "@ionic-native/status-bar/ngx"
import { IonicModule } from "@ionic/angular"
import { IonicStorageModule } from "@ionic/storage"
import { NgbModule } from "@ng-bootstrap/ng-bootstrap"
import { ChartModule } from "angular-highcharts"
import { IonicSelectableModule } from "ionic-selectable"
import { NgCalendarModule } from "ionic2-calendar"
import { NgxPaginationModule } from "ngx-pagination"
import { PapaParseModule } from "ngx-papaparse"
import { NgPipesModule } from "ngx-pipes"
import { AppRoutingModule } from "./app-routing.module"
import { AppComponent } from "./app.component"
import { AuthService } from "./services/auth/auth.service"
import { PublicService } from "./services/data-api/public.service"
import { SentryIonicErrorHandler } from "./services/SentryIonicErrorHandler "
import { StorageService } from "./services/storage/storage.service"

@NgModule({
  declarations: [AppComponent],
  imports: [
    NgxPaginationModule,
    NgPipesModule,
    BrowserModule,
    HttpClientModule,
    IonicSelectableModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    NgbModule,
    NgCalendarModule,
    PapaParseModule,
    ChartModule,
    IonicStorageModule.forRoot({
      name: "MyLocations",
      driverOrder: ["indexeddb", "sqlite", "websql"],
    }),
  ],
  providers: [
    StatusBar,
    SplashScreen,
    AuthService,
    PublicService,
    StorageService,
    { provide: ErrorHandler, useClass: SentryIonicErrorHandler },
    HTTP,
    Network,
    File,
    FileOpener,
    SocialSharing,
    FileTransfer,
    FileTransferObject,
    FormBuilder,
    // DatePicker
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
