import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { distinctUntilChanged } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ScreensizeService {

  private isDestop = new BehaviorSubject(false);

  constructor() { }

  onResize(size) {
    // console.log('size: ', size);
    if (size < 568 ) {
      this.isDestop.next(false);
    } else {
      this.isDestop.next(true);
    }
  }

  isDesktopView(): Observable<boolean> {
    return this.isDestop.asObservable().pipe(distinctUntilChanged());
  }
}
