import { NgModule } from "@angular/core"
import { PreloadAllModules, RouterModule, Routes } from "@angular/router"

const routes: Routes = [
  { path: "", redirectTo: "beforelogin", pathMatch: "full" },
  {
    path: "beforelogin",
    loadChildren: () => import('./beforelogin/beforelogin.module').then(m => m.BeforeloginPageModule),
  },
  { path: "home", loadChildren: () => import('./home/home.module').then(m => m.HomePageModule) },
  {
    path: "inscription",
    loadChildren: () => import('./inscription/inscription.module').then(m => m.InscriptionPageModule),
  },
  {
    path: "calculatrice",
    loadChildren: () => import('./calculatrice/calculatrice.module').then(m => m.CalculatricePageModule),
  },
  {
    path: "authentication",
    loadChildren:
      () => import('./auth/authentication/authentication.module').then(m => m.AuthenticationPageModule),
  },
  { path: "login", loadChildren: () => import('./auth/login/login.module').then(m => m.LoginPageModule) },
  { path: "menu", loadChildren: () => import('./boutique/menu/menu.module').then(m => m.MenuPageModule) },
  {
    path: "dashboard",
    loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardPageModule),
  },
  {
    path: "vente",
    loadChildren: () => import('./boutique/vente/vente.module').then(m => m.VentePageModule),
  },

  {
    path: "depense",
    loadChildren: () => import('./boutique/depense/depense.module').then(m => m.DepensePageModule),
  },
  {
    path: "add-depenses",
    loadChildren:
      () => import('./boutique/depense/add-depenses/add-depenses.module').then(m => m.AddDepensesPageModule),
  },
  {
    path: "vente-detail/:id/:Designation/:quantite/:prix_unit_achat/:prix_unit_vente/:avance/:client/:date/:customer",
    loadChildren:
      () => import('./boutique/details/vente-detail/vente-detail.module').then(m => m.VenteDetailPageModule),
  },
  {
    path: "depense-detail/:id/:Designation/:Prix/:Date",
    loadChildren:
      () => import('./boutique/details/depense-detail/depense-detail.module').then(m => m.DepenseDetailPageModule),
  },
  {
    path: "pret",
    loadChildren: () => import('./boutique/pret/pret.module').then(m => m.PretPageModule),
  },
  {
    path: "accueil",
    loadChildren: () => import('./boutique/accueil/accueil.module').then(m => m.AccueilPageModule),
  },
  {
    path: "accueilS",
    loadChildren: () => import('./stock/accueil/accueil.module').then(m => m.AccueilPageModule),
  },
  {
    path: "produits",
    loadChildren: () => import('./stock/produits/produits.module').then(m => m.ProduitsPageModule),
  },
  {
    path: "categories",
    loadChildren:
      () => import('./stock/produits/categories/categories.module').then(m => m.CategoriesPageModule),
  },
  {
    path: "add-categories",
    loadChildren:
      () => import('./stock/produits/categories/add-categorie/add-categorie.module').then(m => m.AddCategoriePageModule),
  },
  {
    path: "pret-detail/:id/:Client/:clientId/:Telephone/:Designation/:Montant/:Date",
    loadChildren:
      () => import('./boutique/details/pret-detail/pret-detail.module').then(m => m.PretDetailPageModule),
  },

  {
    path: "pret-ajout",
    loadChildren:
      () => import('./boutique/details/pret-ajout/pret-ajout.module').then(m => m.PretAjoutPageModule),
  },

  { path: "menu", loadChildren: () => import('./stock/menu/menu.module').then(m => m.MenuPageModule) },

  {
    path: "entree-detail/:id/:Produit/:Prix_Unitaire/:Quantite/:Fournisseur",
    loadChildren:
      () => import('./stock/details/entree-detail/entree-detail.module').then(m => m.EntreeDetailPageModule),
  },
  {
    path: "sortie-detail/:id/:Produit/:Prix_Unitaire/:Quantite/:Client/:Benefice",
    loadChildren:
      () => import('./stock/details/sortie-detail/sortie-detail.module').then(m => m.SortieDetailPageModule),
  },

  {
    path: "select-entree",
    loadChildren:
      () => import('./stock/ajouter/select-entree/select-entree.module').then(m => m.SelectEntreePageModule),
  },

  {
    path: "sortie-ajout",
    loadChildren:
      () => import('./stock/ajouter/sortie-ajout/sortie-ajout.module').then(m => m.SortieAjoutPageModule),
  },
  {
    path: "menustock",
    loadChildren: () => import('./stock/menustock/menustock.module').then(m => m.MenustockPageModule),
  },
  {
    path: "journalier/:day",
    loadChildren:
      () => import('./stock/briefing/journalier/journalier.module').then(m => m.JournalierPageModule),
  },
  {
    path: "mois/:month",
    loadChildren: () => import('./stock/briefing/mois/mois.module').then(m => m.MoisPageModule),
  },
  {
    path: "annuel/:year",
    loadChildren:
      () => import('./stock/briefing/hebdomadaire/hebdomadaire.module').then(m => m.HebdomadairePageModule),
  },
  {
    path: "journalier1/:day",
    loadChildren:
      () => import('./stock/briefing/journalier1/journalier1.module').then(m => m.Journalier1PageModule),
  },

  {
    path: "mois1/:month",
    loadChildren: () => import('./stock/briefing/mois1/mois1.module').then(m => m.Mois1PageModule),
  },
  {
    path: "annuel1/:year",
    loadChildren:
      () => import('./stock/briefing/hebdomadaire1/hebdomadaire1.module').then(m => m.Hebdomadaire1PageModule),
  },
  {
    path: "journal/:day",
    loadChildren:
      () => import('./boutique/journalier/journalier.module').then(m => m.JournalierPageModule),
  },
  {
    path: "journalier-inv/:day",
    loadChildren:
      () => import('./boutique/journalier-inv/journalier-inv.module').then(m => m.JournalierInvPageModule),
  },

  {
    path: "jour",
    loadChildren: () => import('./boutique/bilan/jour/jour.module').then(m => m.JourPageModule),
  },
  {
    path: "year/:year",
    loadChildren:
      () => import('./boutique/hebdomadaire/hebdomadaire.module').then(m => m.HebdomadairePageModule),
  },

  {
    path: "mensuel/:month",
    loadChildren: () => import('./boutique/mensuel/mensuel.module').then(m => m.MensuelPageModule),
  },
  {
    path: "hebdo",
    loadChildren: () => import('./boutique/bilan/hebdo/hebdo.module').then(m => m.HebdoPageModule),
  },
  {
    path: "mensuel-inv/:month",
    loadChildren:
      () => import('./boutique/mensuel-inv/mensuel-inv.module').then(m => m.MensuelInvPageModule),
  },

  {
    path: "month",
    loadChildren: () => import('./boutique/bilan/mois/mois.module').then(m => m.MoisPageModule),
  },
  {
    path: "year-inv/:year",
    loadChildren: () => import('./boutique/hebdo1/hebdo1.module').then(m => m.Hebdo1PageModule),
  },
  {
    path: "facture",
    loadChildren: () => import('./stock/facture/facture.module').then(m => m.FacturePageModule),
  },
  {
    path: "factur",
    loadChildren: () => import('./boutique/factur/factur.module').then(m => m.FacturPageModule),
  },
  {
    path: "mes-boutiques",
    loadChildren: () => import('./mes-boutiques/mes-boutiques.module').then(m => m.MesBoutiquesPageModule),
  },
  {
    path: "fournisseurs",
    loadChildren: () => import('./fournisseurs/fournisseurs.module').then(m => m.FournisseursPageModule),
  },
  {
    path: "add-fournisseurs",
    loadChildren:
      () => import('./fournisseurs/add-fournisseurs/add-fournisseurs.module').then(m => m.AddFournisseursPageModule),
  },
  {
    path: "clients",
    loadChildren: () => import('./clients/clients.module').then(m => m.ClientsPageModule),
  },
  {
    path: "add-clients",
    loadChildren:
      () => import('./clients/add-clients/add-clients.module').then(m => m.AddClientsPageModule),
  },
  {
    path: "menu-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/menu-gerant/menu-gerant.module').then(m => m.MenuGerantPageModule),
  },
  {
    path: "home-for-gerant",
    loadChildren:
      () => import('./home-for-gerant/home-for-gerant.module').then(m => m.HomeForGerantPageModule),
  },
  {
    path: "accueilG",
    loadChildren:
      () => import('./boutiquesByGerant/accueil/accueil.module').then(m => m.AccueilPageModule),
  },
  {
    path: "accueilSG",
    loadChildren: () => import('./stockByGerant/accueil/accueil.module').then(m => m.AccueilPageModule),
  },
  {
    path: "menu-stock-by-gerant",
    loadChildren:
      () => import('./stockByGerant/menu-stock-by-gerant/menu-stock-by-gerant.module').then(m => m.MenuStockByGerantPageModule),
  },
  {
    path: "vente-by-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/vente-by-gerant/vente-by-gerant.module').then(m => m.VenteByGerantPageModule),
  },
  {
    path: "depense-by-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/depense-by-gerant/depense-by-gerant.module').then(m => m.DepenseByGerantPageModule),
  },
  {
    path: "pret-by-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/pret-by-gerant/pret-by-gerant.module').then(m => m.PretByGerantPageModule),
  },
  {
    path: "client-by-gerant",
    loadChildren:
      () => import('./client-by-gerant/client-by-gerant.module').then(m => m.ClientByGerantPageModule),
  },
  {
    path: "fournisseur-by-gerant",
    loadChildren:
      () => import('./fournisseur-by-gerant/fournisseur-by-gerant.module').then(m => m.FournisseurByGerantPageModule),
  },
  {
    path: "vente-by-gerant-detail/:id/:Designation/:quantite/:prix_unit_achat/:prix_unit_vente/:client/:date/:user",
    loadChildren:
      () => import('./boutiquesByGerant/details/vente-by-gerant-detail/vente-by-gerant-detail.module').then(m => m.VenteByGerantDetailPageModule),
  },
  {
    path: "depense-by-gerant-detail/:id/:Designation/:Prix/:Date/:user",
    loadChildren:
      () => import('./boutiquesByGerant/details/depense-by-gerant-detail/depense-by-gerant-detail.module').then(m => m.DepenseByGerantDetailPageModule),
  },
  {
    path: "pret-by-gerant-detail/:id/:Client/: clientId/:Telephone/:Designation/:Montant/:Date/:user",
    loadChildren:
      () => import('./boutiquesByGerant/details/pret-by-gerant-detail/pret-by-gerant-detail.module').then(m => m.PretByGerantDetailPageModule),
  },
  {
    path: "entrees-by-gerant-detail/:id/:Produit/:Prix_Unitaire/:Quantite/:Fournisseur/:user",
    loadChildren:
      () => import('./stockByGerant/details/entrees-by-gerant-detail/entrees-by-gerant-detail.module').then(m => m.EntreesByGerantDetailPageModule),
  },
  {
    path: "sorties-by-gerant-detail/:id/:Produit/:Prix_Unitaire/:Quantite/:Client/:Benefice",
    loadChildren:
      () => import('./stockByGerant/details/sorties-by-gerant-detail/sorties-by-gerant-detail.module').then(m => m.SortiesByGerantDetailPageModule),
  },
  {
    path: "produits-by-gerant-detail/:id/:Produit/:Quantite/:Prix/:Date/:user",
    loadChildren:
      () => import('./stockByGerant/details/produits-by-gerant-detail/produits-by-gerant-detail.module').then(m => m.ProduitsByGerantDetailPageModule),
  },
  {
    path: "produits-detail/:id/:Produit/:Quantite/:Prix/:Date/:prix_max/:prix_min",
    loadChildren:
      () => import('./stock/details/produits-detail/produits-detail.module').then(m => m.ProduitsDetailPageModule),
  },
  {
    path: "client-detail/:id/:Nom/:Telephone/:Email/:Adresse",
    loadChildren:
      () => import('./details/client-detail/client-detail.module').then(m => m.ClientDetailPageModule),
  },
  {
    path: "fournisseur-detail/:id/:Nom/:Telephone/:Email/:Adresse",
    loadChildren:
      () => import('./details/fournisseur-detail/fournisseur-detail.module').then(m => m.FournisseurDetailPageModule),
  },
  {
    path: "add-vente",
    loadChildren:
      () => import('./boutique/vente/add-vente/add-vente.module').then(m => m.AddVentePageModule),
  },
  {
    path: "add-pret",
    loadChildren: () => import('./boutique/pret/add-pret/add-pret.module').then(m => m.AddPretPageModule),
  },
  {
    path: "add-entree",
    loadChildren:
      () => import('./stock/entree/add-entree/add-entree.module').then(m => m.AddEntreePageModule),
  },
  {
    path: "add-vente-by-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/vente-by-gerant/add-vente-by-gerant/add-vente-by-gerant.module').then(m => m.AddVenteByGerantPageModule),
  },
  {
    path: "add-depense-by-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/depense-by-gerant/add-depense-by-gerant/add-depense-by-gerant.module').then(m => m.AddDepenseByGerantPageModule),
  },
  {
    path: "add-pret-by-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/pret-by-gerant/add-pret-by-gerant/add-pret-by-gerant.module').then(m => m.AddPretByGerantPageModule),
  },
  {
    path: "add-entree-by-gerant",
    loadChildren:
      () => import('./stockByGerant/entrees-by-gerant/add-entree-by-gerant/add-entree-by-gerant.module').then(m => m.AddEntreeByGerantPageModule),
  },
  {
    path: "add-sortie-by-gerant",
    loadChildren:
      () => import('./stockByGerant/sorties-by-gerant/add-sortie-by-gerant/add-sortie-by-gerant.module').then(m => m.AddSortieByGerantPageModule),
  },
  {
    path: "add-produit-by-gerant",
    loadChildren:
      () => import('./stockByGerant/produits-by-gerant/add-produit-by-gerant/add-produit-by-gerant.module').then(m => m.AddProduitByGerantPageModule),
  },
  {
    path: "client-by-gerant-detail/:id/:Nom/:Telephone/:Email/:Adresse",
    loadChildren:
      () => import('./details/client-by-gerant-detail/client-by-gerant-detail.module').then(m => m.ClientByGerantDetailPageModule),
  },
  {
    path: "fournisseur-by-gerant-detail/:id/:Nom/:Telephone/:Email/:Adresse",
    loadChildren:
      () => import('./details/fournisseur-by-gerant-detail/fournisseur-by-gerant-detail.module').then(m => m.FournisseurByGerantDetailPageModule),
  },
  {
    path: "ventes",
    loadChildren: () => import('./by-boutiques/ventes/ventes.module').then(m => m.VentesPageModule),
  },
  {
    path: "ventes-archivees",
    loadChildren:
      () => import('./by-boutiques/ventes/vente-archives/vente-archives.module').then(m => m.VenteArchivesPageModule),
  },
  {
    path: "depenses",
    loadChildren: () => import('./by-boutiques/depenses/depenses.module').then(m => m.DepensesPageModule),
  },
  {
    path: "prets",
    loadChildren: () => import('./by-boutiques/prets/prets.module').then(m => m.PretsPageModule),
  },
  {
    path: "produits-by-shop",
    loadChildren: () => import('./by-boutiques/produits/produits.module').then(m => m.ProduitsPageModule),
  },
  {
    path: "inventaire-by-shop",
    loadChildren:
      () => import('./by-boutiques/inventaire/inventaire.module').then(m => m.InventairePageModule),
  },

  {
    path: "details-inventaire/:id/:Produit/:Quantite/:Prix/:Date/:prix_max/:qtedepart/:statut/:user",
    loadChildren:
      () => import('./by-boutiques/inventaire/details-inventaire/details-inventaire.module').then(m => m.DetailsInventairePageModule),
  },

  {
    path: "category",
    loadChildren:
      () => import('./by-boutiques/produits/category/category.module').then(m => m.CategoryPageModule),
  },
  {
    path: "details-category/:id/:category/:date",
    loadChildren:
      () => import('./by-boutiques/produits/category/details-category/details-category.module').then(m => m.DetailsCategoryPageModule),
  },

  {
    path: "entrees",
    loadChildren: () => import('./by-boutiques/entrees/entrees.module').then(m => m.EntreesPageModule),
  },
  {
    path: "sorties",
    loadChildren: () => import('./by-boutiques/sorties/sorties.module').then(m => m.SortiesPageModule),
  },
  {
    path: "clients-by-boutiques",
    loadChildren:
      () => import('./by-boutiques/clients-by-boutiques/clients-by-boutiques.module').then(m => m.ClientsByBoutiquesPageModule),
  },
  {
    path: "fournisseurs-by-boutiques",
    loadChildren:
      () => import('./by-boutiques/fournisseurs-by-boutiques/fournisseurs-by-boutiques.module').then(m => m.FournisseursByBoutiquesPageModule),
  },
  {
    path: "depenses-detail/:id/:Designation/:Prix/:Date/:user",
    loadChildren:
      () => import('./by-boutiques/depenses/depenses-detail/depenses-detail.module').then(m => m.DepensesDetailPageModule),
  },
  {
    path: "ventes-detail/:id/:Designation/:quantite/:prix_unit_achat/:prix_unit_vente/:avance/:client/:date/:user/:idP/:type",
    loadChildren:
      () => import('./by-boutiques/ventes/ventes-details/ventes-details.module').then(m => m.VentesDetailsPageModule),
  },
  {
    path: "prets-detail/:id/:Client/:Telephone/:Designation/:Montant/:Date/:user",
    loadChildren:
      () => import('./by-boutiques/prets/prets-detail/prets-detail.module').then(m => m.PretsDetailPageModule),
  },
  {
    path: "produit-detail/:id/:Produit/:Quantite/:Prix/:Date/:prix_max/:prix_min/:statut/:user/:global",
    loadChildren:
      () => import('./by-boutiques/produits/produits-detail/produits-detail.module').then(m => m.ProduitsDetailPageModule),
  },
  {
    path: "client-by-boutique-detail/:id/:Nom/:Telephone/:Email/:Adresse",
    loadChildren:
      // tslint:disable-next-line: max-line-length
      () => import('./by-boutiques/clients-by-boutiques/client-by-boutique-detail/client-by-boutique-detail.module').then(m => m.ClientByBoutiqueDetailPageModule),
  },
  {
    path: "fournisseur-by-boutique-detail/:id/:Nom/:Telephone/:Email/:Adresse",
    // tslint:disable-next-line: max-line-length
    loadChildren:
      () => import('./by-boutiques/fournisseurs-by-boutiques/fournisseur-by-boutique-detail/fournisseur-by-boutique-detail.module').then(m => m.FournisseurByBoutiqueDetailPageModule),
  },
  {
    path: "entrees-detail/:id/:Produit/:Prix_Unitaire/:Quantite/:Fournisseur/:user",
    loadChildren:
      () => import('./by-boutiques/entrees/entrees-detail/entrees-detail.module').then(m => m.EntreesDetailPageModule),
  },
  {
    path: "sorties-detail/:id/:Produit/:Prix_Unitaire/:Quantite/:Client/:Benefice",
    loadChildren:
      () => import('./by-boutiques/sorties/sorties-detail/sorties-detail.module').then(m => m.SortiesDetailPageModule),
  },
  {
    path: "bilan-journalier/:day",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/bilan-journalier/bilan-journalier.module').then(m => m.BilanJournalierPageModule),
  },

  {
    path: "bilan-between-dates/:date1/:date2",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/bilan-between-dates/bilan-between-dates.module').then(m => m.BilanBetweenDatesPageModule),
  },
  {
    path: "benefice-between-dates/:date1/:date2",
    loadChildren:
      () => import('./by-boutiques/beneficebyshop/benefice-between-dates/benefice-between-dates.module').then(m => m.BeneficeBetweenDatesPageModule),
  },
  {
    path: "bilan-hebdomadaire/:year",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/bilan-hebdomadaire/bilan-hebdomadaire.module').then(m => m.BilanHebdomadairePageModule),
  },
  {
    path: "bilan-mensuel/:month",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/bilan-mensuel/bilan-mensuel.module').then(m => m.BilanMensuelPageModule),
  },
  {
    path: "mensuelle",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/mensuel/mensuel.module').then(m => m.MensuelPageModule),
  },
  {
    path: "hebdomad",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/hebdomad/hebdomad.module').then(m => m.HebdomadPageModule),
  },
  {
    path: "journale",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/journal/journal.module').then(m => m.JournalPageModule),
  },
  {
    path: "bilan-journalier1/:day",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/bilan-journalier1/bilan-journalier1.module').then(m => m.BilanJournalier1PageModule),
  },
  {
    path: "bilan-hebdomadaire1/:year",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/bilan-hebdomadaire1/bilan-hebdomadaire1.module').then(m => m.BilanHebdomadaire1PageModule),
  },
  {
    path: "bilan-mensuel1/:month",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/bilan-mensuel1/bilan-mensuel1.module').then(m => m.BilanMensuel1PageModule),
  },
  {
    path: "facturation",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/facturation/facturation.module').then(m => m.FacturationPageModule),
  },
  {
    path: "facturations",
    loadChildren:
      () => import('./stockByGerant/bilans/facturations/facturations.module').then(m => m.FacturationsPageModule),
  },
  {
    path: "bilan-jour/:day",
    loadChildren:
      () => import('./stockByGerant/bilans/bilan-jour/bilan-jour.module').then(m => m.BilanJourPageModule),
  },
  {
    path: "bilan-semaine/:year",
    loadChildren:
      () => import('./stockByGerant/bilans/bilan-semaine/bilan-semaine.module').then(m => m.BilanSemainePageModule),
  },
  {
    path: "bilan-moi/:month",
    loadChildren:
      () => import('./stockByGerant/bilans/bilan-moi/bilan-moi.module').then(m => m.BilanMoiPageModule),
  },
  {
    path: "day/:day",
    loadChildren: () => import('./stockByGerant/bilans/day/day.module').then(m => m.DayPageModule),
  },
  {
    path: "week/:year",
    loadChildren: () => import('./stockByGerant/bilans/week/week.module').then(m => m.WeekPageModule),
  },
  {
    path: "months/:month",
    loadChildren:
      () => import('./stockByGerant/bilans/months/months.module').then(m => m.MonthsPageModule),
  },
  {
    path: "bilan-entree",
    loadChildren:
      () => import('./stockByGerant/bilans/bilan-entree/bilan-entree.module').then(m => m.BilanEntreePageModule),
  },
  {
    path: "bilan-sortie",
    loadChildren:
      () => import('./stockByGerant/bilans/bilan-sortie/bilan-sortie.module').then(m => m.BilanSortiePageModule),
  },
  {
    path: "profil",
    loadChildren: () => import('./profil/profil.module').then(m => m.ProfilPageModule),
  },
  {
    path: "reset-password",
    loadChildren:
      () => import('./auth/reset-password/reset-password.module').then(m => m.ResetPasswordPageModule),
  },
  {
    path: "update-password",
    loadChildren:
      () => import('./auth/update-password/update-password.module').then(m => m.UpdatePasswordPageModule),
  },
  {
    path: "calendar",
    loadChildren:
      () => import('./stock/briefing/calendar/calendar.module').then(m => m.CalendarPageModule),
  },
  {
    path: "calendrier",
    loadChildren: () => import('./calendrier/calendrier.module').then(m => m.CalendrierPageModule),
  },
  {
    path: "bilan-mois",
    loadChildren:
      () => import('./stock/briefing/bilan-mois/bilan-mois.module').then(m => m.BilanMoisPageModule),
  },
  {
    path: "bilan-annees",
    loadChildren:
      () => import('./stock/briefing/bilan-annees/bilan-annees.module').then(m => m.BilanAnneesPageModule),
  },
  {
    path: "add-produits",
    loadChildren:
      () => import('./stock/produits/add-produits/add-produits.module').then(m => m.AddProduitsPageModule),
  },
  {
    path: "calendrier-gerant",
    loadChildren:
      () => import('./boutiquesByGerant/bilans/calendrier-gerant/calendrier-gerant.module').then(m => m.CalendrierGerantPageModule),
  },
  {
    path: "calendar-gerant",
    loadChildren:
      () => import('./stockByGerant/bilans/calendar-gerant/calendar-gerant.module').then(m => m.CalendarGerantPageModule),
  },
  {
    path: "bilan-month",
    loadChildren:
      () => import('./stockByGerant/bilans/bilan-month/bilan-month.module').then(m => m.BilanMonthPageModule),
  },
  {
    path: "bilan-year",
    loadChildren:
      () => import('./stockByGerant/bilans/bilan-year/bilan-year.module').then(m => m.BilanYearPageModule),
  },
  {
    path: "add-vente-by-owner",
    loadChildren:
      () => import('./by-boutiques/ventes/add-vente-by-owner/add-vente-by-owner.module').then(m => m.AddVenteByOwnerPageModule),
  },
  {
    path: "add-produit-by-owner",
    loadChildren:
      () => import('./by-boutiques/produits/add-produit-by-owner/add-produit-by-owner.module').then(m => m.AddProduitByOwnerPageModule),
  },
  {
    path: "add-pret-by-owner",
    loadChildren:
      () => import('./by-boutiques/prets/add-pret-by-owner/add-pret-by-owner.module').then(m => m.AddPretByOwnerPageModule),
  },

  {
    path: "add-entree-by-owner",
    loadChildren:
      () => import('./by-boutiques/entrees/add-entree-by-owner/add-entree-by-owner.module').then(m => m.AddEntreeByOwnerPageModule),
  },
  {
    path: "boutique-detail/:id/:nom_boutique",
    loadChildren:
      () => import('./mes-boutiques/boutique-detail/boutique-detail.module').then(m => m.BoutiqueDetailPageModule),
  },
  {
    path: "benefice",
    loadChildren: () => import('./benefices/benefice/benefice.module').then(m => m.BeneficePageModule),
  },
  {
    path: "hebdo/:day",
    loadChildren: () => import('./benefices/hebdo/hebdo.module').then(m => m.HebdoPageModule),
  },
  {
    path: "calendar",
    loadChildren: () => import('./benefices/calendar/calendar.module').then(m => m.CalendarPageModule),
  },
  {
    path: "month",
    loadChildren: () => import('./benefices/month/month.module').then(m => m.MonthPageModule),
  },
  {
    path: "monthly/:month",
    loadChildren: () => import('./benefices/mensuel/mensuel.module').then(m => m.MensuelPageModule),
  },
  {
    path: "beneficebyshop",
    loadChildren:
      () => import('./by-boutiques/beneficebyshop/beneficebyshop.module').then(m => m.BeneficebyshopPageModule),
  },
  {
    path: "by-day/:day",
    loadChildren: () => import('./by-boutiques/by-day/by-day.module').then(m => m.ByDayPageModule),
  },
  {
    path: "by-month/:month",
    loadChildren: () => import('./by-boutiques/by-month/by-month.module').then(m => m.ByMonthPageModule),
  },
  {
    path: "collaborateur",
    loadChildren:
      () => import('./collaborateur/collaborateur.module').then(m => m.CollaborateurPageModule),
  },
  {
    path: "liste-collaborateurs",
    loadChildren:
      () => import('./collaborateur/list-collaborateurs/list-collaborateurs.module').then(m => m.ListCollaborateursPageModule),
  },
  {
    path: "retourproduits",
    loadChildren: () => import('./boutique/retours/retours.module').then(m => m.RetoursPageModule),
  },
  {
    path: "add-gerant",
    loadChildren: () => import('./gerant/add-gerant/add-gerant.module').then(m => m.AddGerantPageModule),
  },
  {
    path: "list-gerants",
    loadChildren:
      () => import('./gerant/list-gerant/list-gerant.module').then(m => m.ListGerantPageModule),
  },
  {
    path: "list-retours",
    loadChildren:
      () => import('./boutique/retours/list-retours/list-retours.module').then(m => m.ListRetoursPageModule),
  },
  {
    path: "collaborateur-detail/:phone/:nom_complet/:user",
    loadChildren:
      () => import('./collaborateur/collaborateur-detail/collaborateur-detail.module').then(m => m.CollaborateurDetailPageModule),
  },
  {
    path: "retour-detail/:product/:price/:quantity/:statuse/:shop/:user",
    loadChildren:
      () => import('./boutique/retours/retour-detail/retour-detail.module').then(m => m.RetourDetailPageModule),
  },
  {
    path: "gerant-detail/:gerant/:phone/:shop/:user",
    loadChildren:
      () => import('./gerant/gerant-detail/gerant-detail.module').then(m => m.GerantDetailPageModule),
  },
  {
    path: "add-boutique",
    loadChildren:
      () => import('./mes-boutiques/add-boutique/add-boutique.module').then(m => m.AddBoutiquePageModule),
  },
  {
    path: "transfert-produit",
    loadChildren:
      () => import('./by-boutiques/produits/transfert-produit/transfert-produit.module').then(m => m.TransfertProduitPageModule),
  },
  {
    path: "historique-transfert",
    loadChildren:
      () => import('./by-boutiques/produits/transfert-produit/historique-transfert/historique-transfert.module').then(m => m.HistoriqueTransfertPageModule),
  },
  {
    path: "inventaires",
    loadChildren: () => import('./inventaires/inventaires.module').then(m => m.InventairesPageModule),
  },
  {
    path: 'details-inventaires/:id/:Produit/:Quantite/:Prix/:Date/:prix_max/:qtedepart/:statut/:user",',
    loadChildren:
      () => import('./inventaires/details-inventaires/details-inventaires.module').then(m => m.DetailsInventairesPageModule),
  },
]

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      relativeLinkResolution: "legacy",
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
