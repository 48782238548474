import { Injectable } from "@angular/core";
import { PluginListenerHandle } from "@capacitor/core";
import { Network } from "@capacitor/network";
import { AlertController } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class NetState {
  [x: string]: any;
  private connected: boolean;
  networkListener: PluginListenerHandle;
  constructor(private alertController: AlertController) {}
  inConnected() {
    return this.connected;
  }
  async addListener(showAlert: boolean = false, cb?: (ok: boolean) => any) {
    const update = async (status) => {
      this.connected = status;
      if (cb) {
        cb(status);
      }
      if (!status) {
        const alert = await this.alertController.create({
          header: "Erreur!!",
          message:
            "Merci de Vérifier que votre appareil est connecté à internet !!",
          buttons: [
            {
              text: "Ok",
              handler: () => {},
            },
          ],
        });
        if (showAlert) {
          await alert.present();
        }
      } else {
        const alert = await this.alertController.create({
          header: "Success",
          message: "Votre connexion est rétablie !!",
          buttons: [
            {
              text: "Ok",
              handler: () => {},
            },
          ],
        });
        if (showAlert) {
          await alert.present();
        }
      }
    };

    const res = await Network.getStatus();
    this.connected = res.connected;
    if (cb) {
      cb(res.connected);
    }
    this.networkListener = Network.addListener(
      "networkStatusChange",
      async (status) => {
        update(status.connected);
      }
    );
  }
}
