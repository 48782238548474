import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";
import { rootUrl } from "../../url-api/server-url";

interface HttpAPi {
  get: (url: string) => any;
  post: (url: string, data: any) => any;
  register: (url: string, data: any) => any;
  postoffline: (url: string, data: any) => any;
  delete: (url: string) => any;
  put: (url: string, data) => any;
}

export class HttpStorage {
  static get(key: string, defaultValue: any = []) {
    const data = localStorage.getItem(key);
    if (data) return JSON.parse(data);
    return defaultValue;
  }
  static set(key: string, value: any) {
    localStorage.setItem(key, JSON.stringify(value));
  }
}

class HttpWeb implements HttpAPi {
  https: HttpClient;
  constructor(https: HttpClient) {
    this.https = https;
  }
  getHeaders = () => {
    return {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    };
  };
  get(url: string) {
    const headers = this.getHeaders();
    console.log("header", headers);
    console.log("url", rootUrl + url);
    return new Promise((resolve, reject) => {
      this.https
        //@ts-ignore
        .get(rootUrl + url, headers)
        .subscribe(resolve, reject);
    });
  }
  post(url: string, data) {
    return new Promise((resolve, reject) => {
      this.https
        //@ts-ignore
        .post(rootUrl + url, data, this.getHeaders())
        .subscribe(resolve, reject);
    });
  }

  register(url: string, data) {
    return new Promise((resolve, reject) => {
      this.https
        //@ts-ignore
        .post(rootUrl + url, data, {})
        .subscribe(resolve, reject);
    });
  }

  postoffline(url: string, data) {
    return new Promise((resolve, reject) => {
      this.https
        //@ts-ignore
        .post(rootUrl + url, data, {})
        .subscribe(resolve, reject);
    });
  }

  put(url: string, data) {
    return new Promise((resolve, reject) => {
      return (
        this.https
          //@ts-ignore
          .put(rootUrl + url, data, this.getHeaders())
          .subscribe(resolve, reject)
      );
    });
  }
  delete(url: string) {
    return new Promise((resolve, reject) => {
      this.https
        //@ts-ignore
        .delete(rootUrl + url, this.getHeaders())
        .subscribe(resolve, reject);
    });
  }
}

@Injectable({
  providedIn: "root",
})
export class PublicService {
  token: any;
  api: HttpAPi;
  constructor(private https: HttpClient, plt: Platform) {
    this.api = new HttpWeb(https);
    console.log("web platform");
  }

  async getVente2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const url = "/api/ventebyshop/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localVentes = HttpStorage.get("added_ventes", []);
    if (localVentes.length > 0) {
      const products = await this.getProduits2(isConnected);
      const clients = await this.getCLients2(isConnected);
      const shops = await this.getBoutiques2(isConnected);
      for (let l of localVentes) {
        l.produit = products.find((p) => p.id == l.produit) || l.produit;
        l.customer = clients.find((p) => p.id == l.customer) || l.client;
        l.shop = shops.find((p) => p.id == l.shop) || l.shop;
      }
    }
    //@ts-ignore
    const all = [...localVentes, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  addCsv(csv, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/importproduitcsv/", csv);
    } else {
      console.log("no connected");
    }
  }

  addvente2(vente, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/ventes/", vente);
    } else {
      const data = HttpStorage.get("added_ventes", []);
      vente.id = new Date().getTime();
      data.push(vente);
      HttpStorage.set("added_ventes", data);
      return vente.sort(
        (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );
    }
  }

  async getCLients2(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/clientsbyboutique/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res.data);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_clients", []);
    const updatedData = HttpStorage.get("updated_clients", []);
    const deletedData = HttpStorage.get("deleteds_clients", []);
    const allData = [...localData, ...data].filter(
      (f) => !deletedData.includes(f.id.toString())
    );
    for (let i of data) {
      for (let u of updatedData) {
        if (i.id == u.id) {
          Object.keys(u).map((k) => {
            i[k] = u[k];
          });
        }
      }
    }
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  async getClientsByBoutique2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const url = "/api/customerbyshop/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    return data.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  updateClient2(id: any, client: any, isConnected: boolean) {
    const ifALocalBoutique = id.toString().length >= 10;
    if (isConnected && !ifALocalBoutique) {
      return this.api.put("/api/customer/" + id + "/", client);
    } else {
      if (ifALocalBoutique) {
        const data = HttpStorage.get("added_clients", []);
        const i = data.findIndex((r) => r.id == id);
        data[i] = { ...data[i], ...client };
        HttpStorage.set("added_clients", data);
      } else {
        const data = HttpStorage.get("updated_clients", []);
        const i = data.findIndex((r) => r.id == id);
        if (i === -1) {
          data.push({
            ...client,
            id,
          });
        } else {
          data[i] = { ...data[i], ...client };
        }
        HttpStorage.set("updated_clients", data);
        console.log("update client", HttpStorage.get("updated_clients"));
      }

      return {
        ...client,
        id,
      };
    }
  }

  deleteClient2(id: any, isConnected: boolean) {
    const ifALocalBoutique = id.toString().length >= 10;
    if (isConnected && !ifALocalBoutique) {
      return this.api.delete("/api/customer/" + id + "/");
    } else {
      if (ifALocalBoutique) {
        const data = HttpStorage.get("added_clients", []).filter(
          (i) => i.id != id
        );
        HttpStorage.set("added_boutiques", data);
      } else {
        const data = HttpStorage.get("deleteds_clients", []);
        data.push(id);
        HttpStorage.set("deleteds_clients", data);
      }
    }
  }

  async getTransfer(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const url = "/api/produit_transferbyshop/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data);
        return data;
      }
    };
    const data = await getData();
    return data.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  addClient2(client: any, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/customers/", client);
    } else {
      const data = HttpStorage.get("added_clients", []);
      client.id = new Date().getTime();
      data.push(client);
      HttpStorage.set("added_clients", data);
      return client.sort(
        (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );
    }
  }
  updateBoutique(id: any, shop: any, isConnected: boolean) {
    const ifALocalBoutique = id.toString().length >= 10;
    if (isConnected && !ifALocalBoutique) {
      return this.api.put("/api/shop/" + id + "/", shop);
    } else {
      if (ifALocalBoutique) {
        const data = HttpStorage.get("added_boutiques", []);
        const i = data.findIndex((r) => r.id == id);
        data[i] = { ...data[i], ...shop };
        HttpStorage.set("added_boutiques", data);
      } else {
        const data = HttpStorage.get("updated_boutiques", []);
        const i = data.findIndex((r) => r.id == id);
        if (i === -1) {
          data.push({
            ...shop,
            id,
          });
        } else {
          data[i] = { ...data[i], ...shop };
        }
        HttpStorage.set("updated_boutiques", data);
      }

      return {
        ...shop,
        id,
      };
    }
  }

  deleteBoutique2(id: any, isConnected: boolean) {
    const ifALocalBoutique = id.toString().length >= 10;
    if (isConnected && !ifALocalBoutique) {
      return this.api.delete("/api/shop/" + id + "/");
    } else {
      if (ifALocalBoutique) {
        const data = HttpStorage.get("added_boutiques", []).filter(
          (i) => i.id != id
        );
        HttpStorage.set("added_boutiques", data);
      } else {
        const data = HttpStorage.get("deleteds_boutiques", []);
        data.push(id);
        HttpStorage.set("deleteds_boutiques", data);
      }
    }
  }

  async getBoutiques2(isConnected: boolean) {
    const getData = async () => {
      const id = localStorage.getItem("userId");
      const url = "/api/shopbyuser/" + id + "/";
      if (isConnected) {
        const data = await this.api.get(url);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_boutiques", []);
    const deleteds = HttpStorage.get("deleteds_boutiques", []);
    const gerants = HttpStorage.get("added_gerants", []);
    const updateds = HttpStorage.get("updated_boutiques", []);
    //@ts-ignore
    const allData = [...data, ...localData].filter(
      (f) => !deleteds.includes(f.id)
    );

    for (let item of allData) {
      for (let u of updateds) {
        if (u.id == item.id) {
          Object.keys(u).map((k) => {
            item[k] = u[k];
          });
        }
      }
      if (!item.gerant) {
        item.gerant = [];
      }
      for (let g of gerants) {
        if (g.store == item.id) {
          item.gerant.push(g);
        }
      }
    }
    return allData.sort(
      (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    );
  }

  async getFournisseur2(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/fournisseursbyboutique/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data.data);
        return data.data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localFour = HttpStorage.get("added_fournisseurs", []);
    const all = [...localFour, ...data];
    return all
      .filter((x) => x.created_by === id || x.created_by.phone === id)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  async getBoutiquesById2(id: any, isConnected: boolean) {
    const data = await this.getBoutiques2(isConnected);
    return data
      .find((f) => f.id == id)
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }
  async getProduitsByBoutique2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const data = await this.getProduits2(isConnected);
    return data
      .filter((f) => f.shop == id)
      .sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());
  }

  async getProduitsByBoutiqueSelected(isConnected: boolean, id: any) {
    const data = await this.getProduits2(isConnected);
    return data
      .filter((f) => f.shop == id)
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }

  async getEntreesByBoutique2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const data = await this.getEntrees2(isConnected);
    console.log(data);
    return data
      .filter((f) => f.shop == id)
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }

  async getDepensesByBoutique2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const data = await this.getDepenses2(isConnected);
    return data
      .filter((f) => f.shop.id == id)
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }

  async getVenteByBoutique2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const data = await this.getVente2(isConnected);
    return data
      .filter((f) => f.shop && f.shop.id == id)
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }

  async getArchivedVenteByBoutique2(isConnected: boolean, id: any) {
    const url = "/api/ventesarchivedbyuser/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    return data.data.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  async getVenteById2(isConnected: boolean, id: any) {
    const url = "/api/vente/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    return data.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  async deleteVente2(id: any, isConnected: boolean) {
    if (isConnected) {
      return this.api.delete("/api/vente/" + id + "/");
    } else {
      const data = HttpStorage.get("deleted_ventes", []);
      data.push(id);
      HttpStorage.set("deleted_ventes", data);
    }
  }

  async getFournisseurByBoutique2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const data = await this.getFournisseur2(isConnected);
    return data
      .filter((f) => f.shop && f.shop.id == id)
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }

  async addFournisseur2(fournisseur: any, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/fournisseurs/", fournisseur);
    } else {
      fournisseur.id = new Date().getTime();
      const data = HttpStorage.get("added_fournisseurs", []);
      data.push(fournisseur);
      HttpStorage.set("added_fournisseurs", data);
      return fournisseur.sort(
        (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );
    }
  }

  addboutique2(boutique, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/shops/", boutique);
    } else {
      boutique.id = new Date().getTime();
      const data = HttpStorage.get("added_boutiques", []);
      data.push(boutique);
      HttpStorage.set("added_boutiques", data);
      return boutique.sort(
        (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );
    }
  }

  addboutiquefromoffline(boutique, isConnected: boolean) {
    if (isConnected) {
      return this.api.postoffline("/api/shops/", boutique);
    } else {
      boutique.id = new Date().getTime();
      const data = HttpStorage.get("added_boutiques", []);
      data.push(boutique);
      HttpStorage.set("added_boutiques", data);
      return boutique.sort(
        (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );
    }
  }

  addDepense2(dep: any, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/depenses/", dep);
    } else {
      dep.id = new Date().getTime();
      const data = HttpStorage.get("added_depenses", []);
      data.push(dep);
      HttpStorage.set("added_depenses", data);
      return dep.sort(
        (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );
    }
  }

  async ajoutgerant2(gerant, isConnected) {
    if (isConnected) {
      return this.api.post("/api/gerants/", gerant);
    } else {
      gerant.id = new Date().getTime();
      const data = HttpStorage.get("added_gerants", []);
      data.push(gerant);
      HttpStorage.set("added_gerants", data);
      return gerant.sort(
        (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
      );
    }
  }
  async addProduit2(produit: any, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/produits/", produit);
    } else {
      produit.id = new Date().getTime();
      const data = HttpStorage.get("added_stocks", []);
      data.push(produit);
      HttpStorage.set("added_stocks", data);
      return produit;
    }
  }

  async addProduitCsv(produit: any, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/importproduitcsv/", produit);
    } else {
    }
  }

  async updateProduit2(id, produit: any, isConnected: boolean) {
    const url = "/api/produit/" + id + "/";

    const ifALocalBoutique = id.toString().length >= 10;
    if (isConnected && !ifALocalBoutique) {
      return this.api.put(url, produit);
    } else {
      if (ifALocalBoutique) {
        const data = HttpStorage.get("added_stocks", []);
        const i = data.findIndex((r) => r.id == id);
        data[i] = { ...data[i], ...produit };
        HttpStorage.set("added_stocks", data);
      } else {
        const data = HttpStorage.get("updated_stocks", []);
        const i = data.findIndex((r) => r.id == id);
        if (i === -1) {
          data.push({
            ...produit,
            id,
          });
        } else {
          data[i] = { ...data[i], ...produit };
        }
        HttpStorage.set("updated_stocks", data);
      }
      return {
        ...produit,
        id,
      };
    }
  }

  async updateCategory2(id, category: any, isConnected: boolean) {
    const url = "/api/category/" + id + "/";
    return this.api.put(url, category);
  }

  async updateVente2(id, vente: any, isConnected: boolean) {
    const url = "/api/venteupdate/" + id + "/";
    const ifALocalBoutique = id.toString().length >= 10;
    if (isConnected && !ifALocalBoutique) {
      return this.api.put(url, vente);
    } else {
      if (ifALocalBoutique) {
        const data = HttpStorage.get("added_ventes", []);
        const i = data.findIndex((r) => r.id == id);
        data[i] = { ...data[i], ...vente };
        HttpStorage.set("added_stocks", data);
      } else {
        const data = HttpStorage.get("updated_ventes", []);
        const i = data.findIndex((r) => r.id == id);
        if (i === -1) {
          data.push({
            ...vente,
            id,
          });
        } else {
          data[i] = { ...data[i], ...vente };
        }
        HttpStorage.set("updated_ventes", data);
      }
      return {
        ...vente,
        id,
      };
    }
  }

  deleteProduit2(id: any, isConnected: boolean) {
    const ifALocalBoutique = id.toString().length >= 10;
    if (isConnected && !ifALocalBoutique) {
      return this.api.delete("/api/produit/" + id + "/");
    } else {
      if (ifALocalBoutique) {
        const data = HttpStorage.get("added_stocks", []).filter(
          (i) => i.id != id
        );
        HttpStorage.set("added_stocks", data);
      } else {
        const data = HttpStorage.get("deleted_stocks", []);
        data.push(id);
        HttpStorage.set("deleted_stocks", data);
      }
    }
  }

  deleteCategory2(id: any, isConnected: boolean) {
    const ifALocalBoutique = id.toString().length >= 10;
    return this.api.delete("/api/category/" + id + "/");
  }

  async getProduitByCategory(isConnected: boolean, id: any) {
    const url = "/api/produitbycategory/" + id + "/";
    if (isConnected) {
      const data = await this.api.get(url).then((d) => d.data);
      HttpStorage.set(url, data);
      return data;
    } else {
      return HttpStorage.get(url, []);
    }
  }

  async getProduitCountByCategory(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/produitCountbycategory/" + id + "/";
    if (isConnected) {
      const data = await this.api.get(url).then((d) => d.items);
      HttpStorage.set(url, data);
      return data;
    } else {
      return HttpStorage.get(url, []);
    }
  }

  async getProduitById2(isConnected: boolean, id: any) {
    const data = await this.getProduits2(isConnected);
    let datas;
    for (let i = 0; i < data.length; i++) {
      if (data[i].id == id) {
        datas = data[i];
      }
    }
    return datas;
  }

  async getProduitByOwner(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/produitsbyboutique/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d.data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    return data;
  }

  async getProduits2(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/produitsbyboutique/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d.data);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_stocks", []);
    const updatedData = HttpStorage.get("updated_stocks", []);
    for (let i of data) {
      for (let u of updatedData) {
        if (i.id == u.id) {
          Object.keys(u).map((k) => {
            i[k] = u[k];
          });
        }
      }
    }
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  async getProduitsGerant(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const url = "/api/produitbyshop/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_stocks", []);
    const updatedData = HttpStorage.get("updated_stocks", []);
    for (let i of data) {
      for (let u of updatedData) {
        if (i.id == u.id) {
          Object.keys(u).map((k) => {
            i[k] = u[k];
          });
        }
      }
    }
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime()
    );
  }

  async getProduits2S(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/produitsbyshop/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d.data);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_stocks", []);
    const updatedData = HttpStorage.get("updated_stocks", []);
    for (let i of data) {
      for (let u of updatedData) {
        if (i.id == u.id) {
          Object.keys(u).map((k) => {
            i[k] = u[k];
          });
        }
      }
    }
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  async getEntrees2(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const url = "/api/entrees/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d.data);
        console.log("dd", data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const datas = await getData();
    return datas;
  }

  async addEntree2(entree: any, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/entrees/", entree);
    } else {
      entree.id = new Date().getTime();
      const data = HttpStorage.get("added_entree", []);
      data.push(entree);
      HttpStorage.set("added_entree", data);
      return entree;
    }
  }

  async getDepenses2(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/depensesbyboutique/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d.data);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_depenses", []);
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  async getCategory(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/category/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d: any) => d);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_category", []);
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  addCategory(category, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/category/", category);
    } else {
      const data = HttpStorage.get("added_category", []);
      category.id = new Date().getTime();
      data.push(category);
      HttpStorage.set("added_category", data);
      return category;
    }
  }

  addPretByShop(pret, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/prets/", pret);
    } else {
      const data = HttpStorage.get("added_prets", []);
      pret.id = new Date().getTime();
      data.push(pret);
      HttpStorage.set("added_prets", data);
      return pret;
    }
  }

  addRemboursement(rm, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/remboursements/", rm);
    } else {
      const data = HttpStorage.get("added_remboursements", []);
      rm.id = new Date().getTime();
      data.push(rm);
      HttpStorage.set("added_remboursements", data);
      return rm;
    }
  }

  async getRemboursementByPret(id: any, isConnected: boolean) {
    const url = "/api/remboursementByPret/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d);
        HttpStorage.set(url, data.data);
        return data.data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_remboursements", []);
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  addboutique(boutique) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/shops/", boutique, {});
  }

  loadRetours() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/retourproduitbyuser/" + id + "/", {});
  }

  addProduit(produit) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/produits/", produit, {});
  }

  loadGerants() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/gerantbyshop/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  async getGerants(isConnected: boolean) {
    const id = localStorage.getItem("idBoutique");
    const b: any = await this.getBoutiquesById2(id, isConnected);
    return b.gerant;
  }

  loadBoutiquesById(id) {
    return this.https.get(rootUrl + "/api/shop/" + id + "/", {});
  }
  loadUserById() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/me/" + id + "/", {});
  }

  loadProduits() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/produitsbyboutique/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  loadProduitById(id) {
    return this.https.get(rootUrl + "/api/produit/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  loadFournisseur() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/fournisseursbyboutique/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  loadClients() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/clientsbyboutique/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  loadCollaborateurs() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/collaborateurbyuser/" + id + "/", {});
  }

  x;

  loadGerant() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/boutiquebyuser/" + id + "/", {});
  }

  loadBoutiques() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/shopbyuser/" + id + "/", {});
  }

  getIdBoutique() {
    const id = localStorage.getItem("shopname");
    return id;
  }

  async getDatasByMonth(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/ventesboutiquebymonth/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const all = await getData();
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  async getVentee(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/ventesbyboutique/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((res) => res);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localVentes = HttpStorage.get("added_ventes", []);
    const updatedData = HttpStorage.get("updated_ventes", []);
    for (let i of data.data) {
      for (let u of updatedData) {
        if (i.id == u.id) {
          Object.keys(u).map((k) => {
            i[k] = u[k];
          });
        }
      }
    }
    if (localVentes.length > 0) {
      const products = await this.getProduits2(isConnected);
      const clients = await this.getCLients2(isConnected);
      const shops = await this.getBoutiques2(isConnected);
      for (let l of localVentes) {
        l.produit = products.find((p) => p.id == l.produit) || l.produit;
        l.customer = clients.find((p) => p.id == l.customer) || l.client;
        l.shop = shops.find((p) => p.id == l.shop) || l.shop;
      }
    }
    //@ts-ignore
    const all = [...localVentes, ...data.data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  loadVente() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/ventesbyboutique/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  async getDepenses(isConnected: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/depenses/" + id + "/";
    const getData = async () => {
      if (isConnected) {
        const data = await this.api.get(url).then((d) => d.data);
        HttpStorage.set(url, data);
        return data;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_depenses", []);
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  loadDepense() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/depensesbyboutique/" + id + "/", {});
  }

  async getPret(isConnected?: boolean) {
    const id = localStorage.getItem("userId");
    const url = "/api/prets/" + id + "/";
    const getData = async () => {
      if (isConnected === true) {
        const data = await this.api.get(url).then((d) => d.data);
        const d = data.filter((r) => r.id_userp.phone === id);
        HttpStorage.set(url, d);
        return d;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_prets", []);
    //@ts-ignore
    const all = [...localData, ...data];
    return all.sort(
      (a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime()
    );
  }

  async getPretByShop(isConnected?: boolean) {
    const id = localStorage.getItem("idBoutique");
    const data = await this.getPret(isConnected);
    return data
      .filter((dt) => dt.shop && dt.shop.id == id)
      .sort((a, b) => new Date(b.Date).getTime() - new Date(a.Date).getTime());
  }

  async getPretGerant(isConnected?: boolean) {
    const id = localStorage.getItem("proprio");
    const ids = localStorage.getItem("idBoutique");
    const url = "/api/prets/" + id + "/";
    const getData = async () => {
      if (isConnected === true) {
        const data = await this.api.get(url).then((d) => d.data);

        const d = data.filter((r) => r.id_userp.phone === id);
        HttpStorage.set(url, d);

        return d;
      } else {
        return HttpStorage.get(url, []);
      }
    };
    const data = await getData();
    const localData = HttpStorage.get("added_prets", []);
    //@ts-ignore
    const all = [...localData, ...data];

    return all.filter((r) => {
      if (r.shop) {
        if (r.shop.id) return r.shop.id == ids;
      }
      return r.shop == ids;
    });
  }

  loadPret() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/pretsbyboutique/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  loadEntree() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/entreesbyboutique/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  loadHistoEntree() {
    const id = localStorage.getItem("userId");
    return this.https.get(
      rootUrl + "/api/historiqueentreebyboutique/" + id + "/",
      {}
    );
  }

  loadSortie() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/sortiesbyboutique/" + id + "/", {});
  }

  loadHistoSortie() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/historiquesortie/" + id + "/", {});
  }

  loadHistoPret() {
    const id = localStorage.getItem("userId");
    return this.https.get(rootUrl + "/api/historiquepret/" + id + "/", {});
  }

  loadHistoPretByCustomer(id) {
    return this.https.get(
      rootUrl + "/api/historiquepretbycustomer/" + id + "/",
      {}
    );
  }
  ///////////////////////////////////////////////////////////////////////////////

  delVente(id) {
    return this.https.delete(rootUrl + `/api/vente/${id}/`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  editVente(vente) {
    const id = localStorage.getItem("idVente");
    return this.https.put(rootUrl + "/api/vente/" + id + "/", vente, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  delDepense(id) {
    return this.https.get(rootUrl + `/api/depensedelete/${id}/`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  editDepense(depense) {
    const id = localStorage.getItem("idDepense");
    return this.https.patch(
      rootUrl + "/api/depenseupdate/" + id + "/",
      depense,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? localStorage.getItem("token")
              : localStorage.getItem("tokenGerant")
          }`,
        }),
      }
    );
  }

  delPret(id) {
    return this.https.delete(rootUrl + `/api/pret/${id}/`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  editPret(pret) {
    const id = localStorage.getItem("idPret");
    return this.https.put(rootUrl + "/api/pret/" + id + "/", pret, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  addUser(user, isConnected: boolean) {
    if (isConnected) {
      return this.api.register("/api/registeruser/", user);
    } else {
      const data = HttpStorage.get("added_users", []);
      user.id = new Date().getTime();
      data.push(user);
      HttpStorage.set("added_users", data);
      return user;
    }
  }

  addgerant(gerant) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/gerants/", gerant, {});
  }

  addProduitTransfer(transfer, isConnected: boolean) {
    if (isConnected) {
      return this.api.post("/api/produit_transfers/", transfer);
    }
  }

  addRetour(retour) {
    const id = localStorage.getItem("userId");
    return this.https.post(
      rootUrl + "/api/retourproduitbyuser/" + id + "/",
      retour,
      {}
    );
  }

  addCollaborateur(collaborateur) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/collaborateur/", collaborateur, {});
  }

  addvente(vente) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/ventes/", vente, {});
  }

  adddepense(depense) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/depenses/", depense, {});
  }

  addpret(pret) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/prets/", pret, {});
  }

  addentree(entre) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/entrees/", entre, {});
  }

  addHistoEntree(entre) {
    const id = localStorage.getItem("userId");
    return this.https.post(
      rootUrl + "/api/historiqueentree/" + id + "/",
      entre,
      {}
    );
  }

  addsortie(sorti: any) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/sorties/", sorti, {});
  }

  addHistoSortie(sorti: any) {
    const id = localStorage.getItem("userId");
    return this.https.post(
      rootUrl + "/api/historiquesortie/" + id + "/",
      sorti,
      {}
    );
  }

  addHistoPret(sorti: any) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/historiquepret/", sorti, {});
  }

  addFournisseur(fournisseur: any) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/fournisseurs/", fournisseur, {});
  }

  addClient(client: any) {
    const id = localStorage.getItem("userId");
    return this.https.post(rootUrl + "/api/customers/", client, {});
  }

  ///////////////////////////////////////////////////////////////////////////////////////////

  delEntree(id) {
    return this.https.delete(rootUrl + `/api/entree/${id}/`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  editEntree(entree) {
    const id = localStorage.getItem("idEntree");
    return this.https.put(rootUrl + "/api/entree/" + id + "/", entree, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  delSortie(id) {
    return this.https.delete(rootUrl + `/api/sortie/${id}/`, {});
  }

  editSortie(sortie) {
    const id = localStorage.getItem("idSortie");
    return this.https.patch(rootUrl + "/api/sortie/" + id + "/", sortie, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  editProduct(produit) {
    const id = localStorage.getItem("idProduit");
    return this.https.patch(
      rootUrl + "/api/produitupdate/" + id + "/",
      produit,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? localStorage.getItem("token")
              : localStorage.getItem("tokenGerant")
          }`,
        }),
      }
    );
  }

  delProduit(id) {
    return this.https.get(rootUrl + `/api/produitdelete/${id}/`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  delBoutique() {
    const id = localStorage.getItem("idBoutique");
    // console.log("http supprime id", id);
    return this.https.delete(rootUrl + "/api/shop/" + id + "/", {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
    });
  }

  editShop(shop) {
    const id = localStorage.getItem("idBoutique");
    return this.https.put(rootUrl + "/api/shop/" + id + "/", shop, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${localStorage.getItem("token")}`,
      }),
    });
  }

  editClient(client) {
    const id = localStorage.getItem("idClient");
    return this.https.put(rootUrl + "/api/customer/" + id + "/", client, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  delClient(id) {
    return this.https.delete(rootUrl + `/api/customer/${id}/`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  editUser(user) {
    const id = localStorage.getItem("userId");
    return this.https.patch(rootUrl + "/api/userupdate/" + id + "/", user, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  editFournisseur(fournisseur) {
    const id = localStorage.getItem("idFournisseur");
    return this.https.put(
      rootUrl + "/api/fournisseur/" + id + "/",
      fournisseur,
      {
        headers: new HttpHeaders({
          Authorization: `Bearer ${
            localStorage.getItem("token")
              ? localStorage.getItem("token")
              : localStorage.getItem("tokenGerant")
          }`,
        }),
      }
    );
  }

  delFournisseur(id) {
    return this.https.delete(rootUrl + `/api/fournisseur/${id}/`, {
      headers: new HttpHeaders({
        Authorization: `Bearer ${
          localStorage.getItem("token")
            ? localStorage.getItem("token")
            : localStorage.getItem("tokenGerant")
        }`,
      }),
    });
  }

  /** Get By Boutique */

  loadClientsByBoutique() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/customerbyshop/" + id + "/", {});
  }

  loadFournisseurByBoutique() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/fournisseurbyshop/" + id + "/", {});
  }

  loadArchHistoryByVente(id) {
    return this.https.get(
      rootUrl + "/api/archivehistorybyvente/" + id + "/",
      {}
    );
  }

  loadArchHistoryByCli(id) {
    return this.https.get(
      rootUrl + "/api/archivehistorybycustomer/" + id + "/",
      {}
    );
  }

  loadModHistoryByVente(id) {
    return this.https.get(
      rootUrl + "/api/modificationhistorybyvente/" + id + "/",
      {}
    );
  }

  loadModHistoryByProd(id) {
    return this.https.get(
      rootUrl + "/api/modificationhistorybyproduct/" + id + "/",
      {}
    );
  }

  loadArchHistoryByProd(id) {
    return this.https.get(
      rootUrl + "/api/archivehistorybyproduct/" + id + "/",
      {}
    );
  }

  loadVenteByBoutique() {
    const id = localStorage.getItem("idBoutique");
    console.log("test ventes boutiquebetw ooddd", id);

    return this.https.get(rootUrl + "/api/ventebyshop/" + id + "/", {});
  }

  loadArchHistoryByDep(id) {
    return this.https.get(
      rootUrl + "/api/archivehistorybydepense/" + id + "/",
      {}
    );
  }

  loadModHistoryByCli(id) {
    return this.https.get(
      rootUrl + "/api/modificationhistorybycustomer/" + id + "/",
      {}
    );
  }

  loadModHistoryByDep(id) {
    return this.https.get(
      rootUrl + "/api/modificationhistorybydepense/" + id + "/",
      {}
    );
  }

  loadModHistoryByFourn(id) {
    return this.https.get(
      rootUrl + "/api/modificationhistorybyfournisseur/" + id + "/",
      {}
    );
  }

  loadArchHistoryByFourn(id) {
    return this.https.get(
      rootUrl + "/api/archivehistorybyfournisseur/" + id + "/",
      {}
    );
  }

  loadModHistoryByPret(id) {
    return this.https.get(
      rootUrl + "/api/modificationhistorybypret/" + id + "/",
      {}
    );
  }

  loadArchHistoryByPret(id) {
    return this.https.get(
      rootUrl + "/api/archivehistorybypret/" + id + "/",
      {}
    );
  }

  loadPretByBoutique() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/pretbyshop/" + id + "/", {});
  }

  async getPretByBoutique() {
    const id = localStorage.getItem("idBoutique");
    const data = await this.getPret(true);
    return data.filter((filtre) => filtre.shop && filtre.shop.id == id);
  }

  loadEntreesByBoutique() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/entreebyshop/" + id + "/", {});
  }

  loadSortiesByBoutique() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/sortiebyshop/" + id + "/", {});
  }

  loadProduitsByBoutique() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/produitbyshop/" + id + "/", {});
  }

  loadHistoPretByBoutique() {
    const id = localStorage.getItem("idBoutique");
    return this.https.get(rootUrl + "/api/historiquepret/" + id + "/", {});
  }

  /** By client */
  loadVenteByClient() {
    const id = localStorage.getItem("idClient");
    return this.https.get(rootUrl + "/api/ventesbyclient/" + id + "/", {});
  }

  loadEntreesByFournisseur() {
    const id = localStorage.getItem("idFournisseur");
    return this.https.get(
      rootUrl + "/api/entreesbyfournisseur/" + id + "/",
      {}
    );
  }

  loadSortiesByClient() {
    const id = localStorage.getItem("idClient");
    return this.https.get(rootUrl + "/api/sortiesbyclient/" + id + "/", {});
  }
}
